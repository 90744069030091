import moment from 'moment';
import Modal from 'react-modal';
import { useState } from 'react';

import './styles.css';

import UserHeader from './UserHeader';
import MemberForm from './MemberForm';
import ModalContainer from './ModalContainer';
import MultiColorButton from '../Buttons/Multicolor';
import { toastError, toastSuccess } from '../../services/toast';

const UserRequest = ({ setModalOpen, user }) => (
  <ModalContainer Header={() => <UserHeader user={user} />} setModalOpen={setModalOpen} title={user.name}>
    <div className="hollow-background" style={{ margin: '5%', padding: 20, minHeight: 352, borderRadius: 10 }}>
      <div className="italic" style={{ fontSize: 17, color: '#707070', whiteSpace: 'pre-line' }}>
        {user.request || 'Aucune description n\'a été renseignée'}
      </div>
    </div>
  </ModalContainer>
);

const UserFormDetails = ({ setModalOpen, user }) => (
  <ModalContainer Header={() => <UserHeader user={user} />} setModalOpen={setModalOpen} title={user.name}>
    <div style={{ margin: '2.5% 10% 10% 10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
        <div className="user-form-label">
          Métier
        </div>
        <div className="user-form-value">
          {user.job || 'Non renseigné'}
        </div>
        <div className="user-form-label">
          Email
        </div>
        <div className={user.email ? 'user-form-value-email' : 'user-form-value'}>
          {user.email || 'Non renseigné'}
        </div>
        <div className="user-form-label">
          Date de naissance
        </div>
        <div className="user-form-value">
          {(user.birthdate && moment(user.birthdate).format('DD/MM/YYYY')) || 'Non renseigné'}
        </div>
        {
          user.group && (
            <div>
              <div className="user-form-label">
                Groupe
              </div>
              <div className="user-form-value">
                {user.group}
              </div>
            </div>
          )
        }
      </div>
      <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
        <div className="user-form-label">
          Entreprise
        </div>
        <div className="user-form-value">
          {user.company || 'Non renseigné'}
        </div>
        <div className="user-form-label">
          Téléphone
        </div>
        <div className="user-form-value">
          {user.phone || 'Non renseigné'}
        </div>
        {
          user.adhesionDate && (
            <div>
              <div className="user-form-label">
                Date d'adhésion
              </div>
              <div className="user-form-value">
                {moment(user.adhesionDate).format('DD/MM/YYYY') || 'Non renseigné'}
              </div>
            </div>
          )
        }
        {
          /*
                  <div className="user-form-label">
          Groupe
        </div>
           */
        }
      </div>
    </div>
  </ModalContainer>
);

const UserFormEdit = ({ setModalOpen, setRefreshing, user }) => (
  <MemberForm type="edit" setRefreshing={setRefreshing} setModalOpen={setModalOpen} member={user} />
);

const AcceptContainer = ({ setModalOpen, setRefreshing, data, dataType }) => {
  let acceptText;
  if (dataType === 'adhesion') {
    acceptText = 'En confimant vous accepterez cette demande d\'adhesion';
  }

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
        <div style={{ fontSize: 20, marginTop: 20 }}>
          {acceptText}
        </div>
        <div
          onClick={async () => {
            try {
              await data.actions.onAccept();
              toastSuccess('Adhesion acceptée, le membre a été ajouté !');
              if (setRefreshing) {
                setRefreshing(true);
              }
            } catch (err) {
              toastError();
            }
          }}
          style={{ marginTop: 40, marginBottom: 20 }}
        >
          <MultiColorButton width={200} text="Confimer" />
        </div>
      </div>
    </ModalContainer>
  );
};

const DeleteContainer = ({ setModalOpen, setRefreshing, data, dataType }) => {
  let deleteText;
  if (dataType === 'admin') {
    deleteText = 'Cette action supprimera le role admin de ce membre';
  }
  if (dataType === 'adhesion') {
    deleteText = 'En confimant vous refuserez cette demande d\'adhesion';
  }
  if (dataType === 'user') {
    deleteText = 'En confimant vous supprimerez ce membre de votre réseaux';
  }

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
        <div style={{ fontSize: 20, marginTop: 20 }}>
          {deleteText}
        </div>
        <div
          onClick={async () => {
            try {
              await data.actions.onDelete();
              if (dataType === 'admin') {
                toastSuccess('Role admin supprimé.');
              }
              if (dataType === 'adhesion') {
                toastSuccess('Adhesion refusée.');
              }
              if (dataType === 'user') {
                toastSuccess('Membre supprimé.');
              }
              if (setRefreshing) {
                setRefreshing(true);
              }
            } catch (err) {
              toastError();
            }
          }}
          style={{ marginTop: 40, marginBottom: 20 }}
        >
          <MultiColorButton width={200} text="Confirmer" />
        </div>
      </div>
    </ModalContainer>
  );
};

const FirstContributionPayment = ({ setModalOpen, setRefreshing, data }) => {
  const [price, setPrice] = useState();

  const onSubmit = async () => {
    try {
      await data.sendContributionLink(price);
      toastSuccess('Lien envoyé !');
      if (setRefreshing) {
        setRefreshing(true);
      }
    } catch (err) {
      toastError();
    }
  };

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
        <div style={{ fontSize: 20, marginTop: 20 }}>
          Envoyer le paiement de la cotisation
        </div>
        <label className="user-form-edit-label">
          Prix en euros HT {data.tva > 0 && `(la TVA de ${data.tva}% sera calculée automatiquement)`}
        </label>
        <input type="number" style={{ width: '60%' }} name="price" value={price} onChange={(e) => setPrice(e.target.value)} className="input italic hollow-background" />
        <div style={{ marginTop: 40, marginBottom: 20 }} onClick={onSubmit}>
          <MultiColorButton width={200} text="Envoyer" />
        </div>
      </div>
    </ModalContainer>
  );
};

const ContributionPayment = ({ setModalOpen, setRefreshing, data }) => {
  const [price, setPrice] = useState();
  const [adhesionDate, setAdhesionDate] = useState();
  const [type, setType] = useState('STRIPE');

  const onSubmit = async () => {
    try {
      await data.addContribution(price, type, adhesionDate);
      if (type === 'STRIPE') {
        toastSuccess('Lien envoyé !');
      }
      if (type === 'INTERN') {
        toastSuccess('Cotisation enregistré !');
      }
      if (setRefreshing) {
        setRefreshing(true);
      }
    } catch (err) {
      if (err.response?.data?.message?.includes('Account not setup')) {
        toastError('Vous devez d\'abord configurer votre compte stripe dans l\'onglet Ma facturation');
      } else {
        toastError();
      }
    }
  };
  console.log('data', data);

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
        {data.pendingContribution && 'Une demande de paiement a déjà été envoyée'}
        <div style={{ fontSize: 20, marginTop: 20 }}>
          Que voulez-vous faire ?
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30, cursor: 'pointer' }} onClick={() => setType('STRIPE')}>
          <input type="radio" value="STRIPE" name="link" onChange={(e) => setType(e.target.value)} checked={type === 'STRIPE'} />
          Envoyer un lien de paiement
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, cursor: 'pointer' }} onClick={() => setType('INTERN')}>
          <input type="radio" value="INTERN" name="intern" onChange={(e) => setType(e.target.value)} checked={type === 'INTERN'} />
          Enregistrer une cotisation déjà payée
        </div>
        <label className="user-form-edit-label">
          Prix en euros HT {data.tva > 0 && `(la TVA de ${data.tva}% sera calculée automatiquement)`}
        </label>
        <input type="number" style={{ width: '60%' }} name="price" value={price} onChange={(e) => setPrice(e.target.value)} className="input hollow-background" />
        {type === 'INTERN' && <label className="user-form-edit-label">
          Date de la cotisation
        </label>}
        {type === 'INTERN' && <input type="text" style={{ width: '60%' }} name="adhesionDate" placeholder={'jj/mm/aaaa'} onChange={(e) => setAdhesionDate(e.target.value)} className="input hollow-background" />}
        <div className="burgundy italic" style={{ marginTop: 40 }}>
          Le paiement ou l'enregistrement d'une cotisation est valable pour 1 an.
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ marginTop: 10, marginBottom: 20 }} onClick={() => onSubmit()}>
            <MultiColorButton width={200} text={type === 'STRIPE' ? 'Envoyer' : 'Enregister'} />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

const TableModal = ({ data, dataType, modalIsOpen, setModalOpen, type, setRefreshing }) => {
  let modalWidth = '100%';
  if (
    type === 'accept' ||
    type === 'delete' ||
    type === 'form-edit' ||
    type === 'form-details' ||
    type === 'contribution' ||
    type === 'first-contribution'
  ) {
    modalWidth = '40%';
  }
  if (type === 'request') {
    modalWidth = '60%';
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Example Modal"
      onRequestClose={() => setModalOpen(false)}
      style={{
        content: {
          top: '50%',
          left: '50%',
          padding: 0,
          bottom: 'auto',
          borderRadius: 20,
          width: modalWidth,
          overflow: 'hidden',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {
          zIndex: 100,
          background: 'rgba(0, 0, 0, 0.4)'
        },
      }}
    >
      {
        type === 'request' && (
          <UserRequest setModalOpen={setModalOpen} user={{ ...data, ...data.data }} />
        )
      }
      {
        type === 'form-details' && (
          <UserFormDetails setModalOpen={setModalOpen} user={{ ...data, ...data.data }} />
        )
      }
      {
        type === 'form-edit' && (
          <UserFormEdit setModalOpen={setModalOpen} setRefreshing={setRefreshing} user={{ ...data, ...data.data }} />
        )
      }
      {
        type === 'delete' && (
          <DeleteContainer dataType={dataType} setRefreshing={setRefreshing} setModalOpen={setModalOpen} data={{ ...data, ...data.data }}/>
        )
      }
      {
        type === 'accept' && (
          <AcceptContainer dataType={dataType} setRefreshing={setRefreshing} setModalOpen={setModalOpen} data={{ ...data, ...data.data }}/>
        )
      }
      {
        type === 'first-contribution' && (
          <FirstContributionPayment setModalOpen={setModalOpen} setRefreshing={setRefreshing} data={{ ...data, ...data.data }} />
        )
      }
      {
        type === 'contribution' && (
          <ContributionPayment setModalOpen={setModalOpen} setRefreshing={setRefreshing} data={{ ...data, ...data.data }} />
        )
      }
    </Modal>
  );
};

export default TableModal;
