import { Fragment } from 'react';

const UserHeader = ({ user }) => (
  <Fragment>
    <img src={user.pictureUrl || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} style={{ width: 90, height: 90, objectFit: 'cover', borderRadius: 45 }} />
    <div className="bold" style={{ fontSize: 25, marginLeft: 20 }}>
      {user.name}
    </div>
  </Fragment>
);

export default UserHeader;
