import { useContext, useEffect, useState } from 'react';
import { Oval } from  'react-loader-spinner';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Menu from './Menu';
import useWindowSize from '../WindowSize';
import { getAccessToken, getProfile } from '../../services/api';

import PageHeader from '../PageHeader';
import { GlobalContext } from '../../contexts/global';

const Layout = ({
  refresh,
  children,
  setRefreshing,
  pageHeaderIcon,
  pageHeaderTitle,
  pageHeaderButtons,
  useFooter = true,
  useChildrenStyle = true,
  loadResource = undefined
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(!!loadResource);

  const { profile, setProfile, groups, parentNetworkId, setParentNetworkId } = useContext(GlobalContext);

  let menuWidth = width * 0.16;
  let containerWidth = width - menuWidth;

  if (menuWidth < 230) {
    menuWidth = 230;
  }
  if (containerWidth < 1000) {
    containerWidth = 1000;
  }
  const tryAuthenticate = async (authCode) => {
    const { token } = await getAccessToken(authCode);
    if (token) {
      navigate('/');
    }
  };

  const loadResourceAsync = async () => {
    const network = searchParams.get('network');
    const authCode = searchParams.get('auth_code');
    if (network) {
      setParentNetworkId(network);
    }
    if (authCode) {
      await tryAuthenticate(authCode);
    }
    if (loadResource) {
      setLoading(true);
      await loadResource();
      setLoading(false);
    }
    const fetchedProfile = await getProfile(parentNetworkId);
    if (profile.user?.picture_url !== fetchedProfile.user.picture_url ||
      profile.parentNetwork?.logo_url !== fetchedProfile.parentNetwork.logo_url) {
      setProfile(fetchedProfile);
    }
  };

  useEffect(() => {
    loadResourceAsync();
    window.dispatchEvent(new Event('resize'));
  }, [width, groups]);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      if (setRefreshing) {
        setRefreshing(false);
      }
      loadResourceAsync();
    }
  }, [refresh]);

  const ChildrenContainer = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '90vh', alignItems: 'center', justifyContent: 'center' }}>
          <Oval width={50} height={50} color="#CD004B" secondaryColor="rgba(205,0,75,0.7)" />
        </div>
      );
    }
    if (!useChildrenStyle) {
      return children;
    }
    return (
      <div className="default-container">
        {children}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: width, overflow: 'hidden', overflowX: 'scroll', height: '100%', backgroundColor: '#F2F2F2' }}>
      <div style={{ zIndex: 800, marginTop: 115, position: 'fixed', width: '100%', height: 20, background: 'linear-gradient(rgba(0, 0, 0,.05), rgba(0,0,0,0))' }}/>
      <Menu profile={profile} width={menuWidth} />
      <div
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          minHeight: '100vh',
          paddingLeft: menuWidth,
          flexDirection: 'column',
          boxSizing: 'border-box',
        }}
      >
        <PageHeader relativeWidth={width - menuWidth} profile={profile} buttons={pageHeaderButtons} title={pageHeaderTitle} icon={pageHeaderIcon} />
        <div style={{ width: containerWidth, minHeight: '90vh' }}>
          <ChildrenContainer />
        </div>
        {/* useFooter && <Footer width={width - menuWidth} /> */}
      </div>
    </div>
  );
};

export default Layout;
