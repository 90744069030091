import React, { createContext, useMemo, useState, useEffect } from 'react';
import { getGroups } from '../services/api';

export const GlobalContext = createContext({});

export function GlobalProvider({ children }) {
  const [allGroups, setAllGroups] = useState([]);
  const [groups, setGroups] = useState(JSON.parse(localStorage.getItem('GROUPS') || '[]'));
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('PROFILE') || '{}'));
  const [parentNetworkId, setParentNetworkId] = useState(localStorage.getItem('PARENT_NETWORK_ID') || undefined);

  const forwardValues = useMemo(() => ({
    profile, setProfile, allGroups, groups, setGroups, parentNetworkId, setParentNetworkId,
  }), [
    profile, setProfile, allGroups, groups, setGroups, parentNetworkId, setParentNetworkId,
  ]);

  const save = async () => {
    await localStorage.setItem('GROUPS', JSON.stringify(groups));
    await localStorage.setItem('PROFILE', JSON.stringify(profile));
    await localStorage.setItem('PARENT_NETWORK_ID', parentNetworkId);
  };

  window.onbeforeunload = () => {
    save();
  };

  const loadGroups = async () => {
    const fetchedGroups = await getGroups(parentNetworkId);
    setAllGroups(fetchedGroups);
  };

  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <GlobalContext.Provider value={forwardValues}>
      {children}
    </GlobalContext.Provider>
  );
}
