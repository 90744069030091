const {
  REACT_APP_API_URL = 'https://api.othr.pro',
  REACT_APP_APP_URL = 'https://app.othr.pro',
} = process.env;
console.log('env', process.env);

console.log('API_URL', REACT_APP_API_URL);
console.log('APP_URL', REACT_APP_APP_URL);

export {
  REACT_APP_API_URL,
  REACT_APP_APP_URL,
};
