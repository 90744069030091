import React, { useContext, useState } from 'react';

import { REACT_APP_APP_URL } from '../../config';
import { GlobalContext } from '../../contexts/global';
import MediumsIcon from '../../assets/pageIcon/mediums.svg';

import tableColumns from './tableColumns';
import { formatMediasData } from './formatData';
import { getMedias, getMediasRight } from '../../services/api';

import Table from '../../components/Table';
import Layout from '../../components/Layout';
import NoResources from '../../components/NoResources';
import TableFilters from '../../components/Table/Filters';

const Medias = () => {
  const { groups, parentNetworkId } = useContext(GlobalContext);

  const [medias, setMedias] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [canAddMedia, setCanAddMedia] = useState(false);

  const loadResource = async () => {
    const fetchedMedias = await getMedias(parentNetworkId, groups);
    const canEdit = await getMediasRight(parentNetworkId, groups);
    setCanAddMedia(canEdit);
    setMedias(formatMediasData(fetchedMedias));
  };

  return (
    <Layout
      loadResource={loadResource}
      pageHeaderIcon={MediumsIcon}
      pageHeaderTitle="Mes médias"
      pageHeaderButtons={[
        { title: 'Publier un Article', onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=article`), active: canAddMedia },
        { title: 'Publier un Podcast', onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=podcast`), active: canAddMedia },
        { title: 'Publier une Vidéo', onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=video`), active: canAddMedia },
      ]}
    >
      {
        medias?.length > 0 && (
          <TableFilters
            value={filterValue}
            setValue={setFilterValue}
            onFilterChange={(filter) => {
              const getTargetData = (item) => item.items.find((i) => i.type === 'title')?.content;
              if (filter === 'asc') {
                setMedias([...medias.sort((a, b) => getTargetData(a) > getTargetData(b) ? 1 : -1)]);
              } else if (filter === 'desc') {
                setMedias([...medias.sort((a, b) => getTargetData(a) < getTargetData(b) ? 1 : -1)]);
              } else if (filter.includes('type')) {
                if (filter.includes('article')) {
                  setMedias([...medias.sort((a) => a.type === 'article' ? 1 : -1)]);
                }
                if (filter.includes('video')) {
                  setMedias([...medias.sort((a) => a.type === 'video' ? 1 : -1)]);
                }
                if (filter.includes('podcast')) {
                  setMedias([...medias.sort((a) => a.type === 'podcast' ? 1 : -1)]);
                }
              }
            }}
            filters={[
              {
                placeholder: 'Titre',
                name: 'Classer par:',
                options: [
                  {
                    name: 'A à Z',
                    value: 'asc',
                  },
                  {
                    name: 'Z à A',
                    value: 'desc',
                  },
                ],
              },
              {
                placeholder: 'Media',
                name: 'Type',
                options: [
                  {
                    name: 'Article',
                    value: 'type-article',
                  },
                  {
                    name: 'Podcast',
                    value: 'type-article',
                  },
                  {
                    name: 'Video',
                    value: 'type-video',
                  },
                ],
              }
            ]}
          />
        )
      }
      {
        medias?.length > 0 ? (
          <Table
            type="media"
            data={medias}
            columns={tableColumns}
          />
        ) : (
          <NoResources
            noResourceText="Vous n'avez pas encore crée de médias pour votre réseau"
            findResourceText="Créer un média pour le retrouver ici"
            buttons={canAddMedia && [
              {
                title: 'Créer un article',
                onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=article`)
              },
              {
                title: 'Créer un podcast',
                onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=podcast`)
              },
              {
                title: 'Créer une vidéo',
                onClick: () => window.open(`${REACT_APP_APP_URL}/media/create?type=video`)
              }
            ]}
          />
        )
      }
    </Layout>
  );
};

export default Medias;
