import { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

import {
  getStats,
  getStatsMembersNetworks,
  getStatsMembersSpheres,
  getStatsEventsFills,
  getStatsEventsAudience,
  getStatsVisibilityTime,
  getStatsVisibilityRanking,
  getStatsRecommendationsTime,
  getStatsRecommendationsSphere,
  getStatsRecommendationsMembers,
} from '../../services/api';

import Layout from '../../components/Layout';
import BarChart from '../../components/Charts/BarChart';
import LineChart from '../../components/Charts/LineChart';
import ComposedChart from '../../components/Charts/ComposedChart';

import { GlobalContext } from '../../contexts/global';
import StackedBarChart from '../../components/Charts/StackedBarChart';
import DatePicker from '../../components/DatePicker';
import moment from 'moment';

const monthsNames = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
const daysNames = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
const options = [{ value: 'given', label: 'Envoyées' }, { value: 'received', label: 'Reçuexs' }, { value: 'givenAmount', label: 'CA généré' }, { value: 'receivedAmount', label: 'CA reçu' }];

const Stats = () => {
  const [ eventsAudience, setEventsAudience ] = useState({ value: 'day', label: 'Par jour' });
  const [ recommendationsTime, setRecommendationsTime ] = useState({ value: 'day', label: 'Par jour' });
  const [ networkVisibility, setNetworkVisibility ] = useState({ value: 'day', label: 'Par jour' });
  const [ startDate, setStartDate ] = useState(moment().subtract(1, 'month').toDate());
  const [ endDate, setEndDate ] = useState(new Date());
  const { groups, parentNetworkId } = useContext(GlobalContext);
  const [ data, setData ] = useState({});
  const [ recommendationsMembers, setRecommendationsMembers ] = useState(null);
  const [ sortRecos, setSortRecos ] = useState('given');

  const loadResource = async () => {
    setData({
      stats: await getStats(parentNetworkId, groups),
      membersSpheres: await getStatsMembersSpheres(parentNetworkId, groups),
      membersNetworks: await getStatsMembersNetworks(parentNetworkId),
      eventsFills: await getStatsEventsFills(parentNetworkId, groups),
      eventsAudience: await getStatsEventsAudience(parentNetworkId, groups),
      visibilityTime: await getStatsVisibilityTime(parentNetworkId),
      visibilityRanking: await getStatsVisibilityRanking(parentNetworkId),
      recommendationsTime: await getStatsRecommendationsTime(parentNetworkId, groups),
      recommendationsSphere: await getStatsRecommendationsSphere(parentNetworkId, groups),
    });
    setRecommendationsMembers(await getStatsRecommendationsMembers(parentNetworkId, groups, startDate, endDate));
  };

  const updateRecommendationsMembers = async () => {
    setRecommendationsMembers(await getStatsRecommendationsMembers(parentNetworkId, groups, startDate, endDate));
  };

  useEffect(() => {
    updateRecommendationsMembers();
  }, [ startDate, endDate ]);

  const eventsAudienceData = useMemo(() => {
    if (eventsAudience.value === 'day') {
      return data.eventsAudience?.day.map((d, i) => ({ ...d, name: daysNames[i] }));
    } else {
      return data.eventsAudience?.month.map((d) => ({ ...d, name: monthsNames[d.name] }));
    }
  }, [ eventsAudience, data?.eventsAudience ]);

  const recommandationTimeData = useMemo(() => {
    if (recommendationsTime.value === 'day') {
      return data.recommendationsTime?.day.map((d, i) => ({ ...d, name: daysNames[i] }));
    } else if (recommendationsTime.value === 'month') {
      return data.recommendationsTime?.month.map((d) => ({ ...d, name: monthsNames[d.name] }));
    } else {
      return data.recommendationsTime?.week;
    }
  }, [ data?.recommendationsTime, recommendationsTime ]);

  const getNetworkVisibilityTime = () => {
    if (networkVisibility.value === 'day') {
      return data.visibilityTime?.day.map((d, i) => ({ ...d, name: daysNames[i] }));
    } else {
      return data.visibilityTime?.month.map((d) => ({ ...d, name: monthsNames[d.name] }));
    }
  };

  return (
    <Layout loadResource={loadResource} pageHeaderTitle="Statistiques">
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {data.stats && <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 300, backgroundColor: 'white', borderRadius: 5, boxShadow: '2px 2px 5px 2px #00000022', padding: 20 }}>
              <h2 style={{ fontFamily: 'lato', marginBlock: 0, fontSize: 16 }}>Membres Othr</h2>
              <span style={{ color: '#CD004B', fontFamily: 'lato', fontSize: 35 }}>{data.stats.othrs.value}</span>
              <span style={{ color: '#28CB80', fontFamily: 'lato', fontSize: 14, marginLeft: 10 }}>{data.stats.othrs.variation === '0' ? '=' : data.stats.othrs.variation}</span>
            </div>
            <div style={{ width: 300, backgroundColor: 'white', borderRadius: 5, boxShadow: '2px 2px 5px 2px #00000022', padding: 20, marginLeft: 40 }}>
              <h2 style={{ fontFamily: 'lato', marginBlock: 0, fontSize: 16 }}>Membres du réseau</h2>
              <span style={{ color: '#CD004B', fontFamily: 'lato', fontSize: 35 }}>{data.stats.members.value}</span>
              <span style={{ color: '#28CB80', fontFamily: 'lato', fontSize: 14, marginLeft: 10 }}>{data.stats.members.variation === '0' ? '=' : data.stats.members.variation}</span>
            </div>
            <div style={{ width: 300, backgroundColor: 'white', borderRadius: 5, boxShadow: '2px 2px 5px 2px #00000022', padding: 20, marginLeft: 40 }}>
              <h2 style={{ fontFamily: 'lato', marginBlock: 0, fontSize: 16 }}>CA HT annuel</h2>
              <span style={{ color: '#CD004B', fontFamily: 'lato', fontSize: 35 }}>{data.stats.ca.yearCa}</span>
            </div>
            <div style={{ width: 300, backgroundColor: 'white', borderRadius: 5, boxShadow: '2px 2px 5px 2px #00000022', padding: 20, marginLeft: 40 }}>
              <h2 style={{ fontFamily: 'lato', marginBlock: 0, fontSize: 16 }}>CA HT mensuel</h2>
              <span style={{ color: '#CD004B', fontFamily: 'lato', fontSize: 35 }}>{data.stats.ca.monthCa}</span>
              <span style={{ color: '#28CB80', fontFamily: 'lato', fontSize: 14, marginLeft: 10 }}>{data.stats.ca.monthCaVariation === '0' ? '=' : data.stats.ca.monthCaVariation}</span>
            </div>
            <div style={{ width: 300, backgroundColor: 'white', borderRadius: 5, boxShadow: '2px 2px 5px 2px #00000022', padding: 20, marginLeft: 40 }}>
              <h2 style={{ fontFamily: 'lato', marginBlock: 0, fontSize: 16 }}>CA HT hebdomadaire</h2>
              <span style={{ color: '#CD004B', fontFamily: 'lato', fontSize: 35 }}>{data.stats.ca.weekCa}</span>
              <span style={{ color: '#28CB80', fontFamily: 'lato', fontSize: 14, marginLeft: 10 }}>{data.stats.ca.weekCaVariation === '0' ? '=' : data.stats.ca.weekCaVariation}</span>
            </div>
          </div>}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20, marginTop: 60 }}>
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>RECOMMANDATIONS</p>
            <div>
              <Select
                options={[
                  { value: 'day', label: 'Par jour' },
                  { value: 'week', label: 'Par semaine' },
                  { value: 'month', label: 'Par mois' },
                ]}
                defaultValue={recommendationsTime}
                onChange={(e) => setRecommendationsTime(e)}
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <StackedBarChart
              title={'Moyennes par sphère'}
              data={data.recommendationsSphere}
              useData={[{
                fill: true,
                key: 'internal',
                color: '#F78B14',
                strokeDasharray: false,
                tooltipValue: 'Interne',
                type: 'bar1'
              }, {
                fill: true,
                key: 'external',
                color: '#CD004B',
                strokeDasharray: false,
                tooltipValue: 'Externe',
                type: 'bar2'
              }]}
              showXAxis={true}
              showGrid={true}
            />
            <StackedBarChart
              title={'Recommandations envoyées par vos membres'}
              data={recommandationTimeData}
              useData={[{
                fill: true,
                key: 'internal',
                type: 'bar1',
                color: '#F78B14',
                strokeDasharray: false,
                tooltipValue: 'Internes'
              }, {
                fill: true,
                key: 'external',
                type: 'bar2',
                color: '#CD004B',
                strokeDasharray: false,
                tooltipValue: 'Externes'
              }, {
                key: 'average',
                type: 'line',
                color: '#CD004B',
                tooltipValue: 'Moyenne départementale',
              }]}
              showXAxis={true}
              showGrid={true}
            />
          </div>
          <div>
            <h2 style={{ marginTop: 50, fontSize: 18 }}>Détail des recommandations de vos membres</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>Début :</span>
              <DatePicker onChange={setStartDate} value={startDate} />
              <span style={{ marginLeft: 20, marginRight: 10 }}>Fin :</span>
              <DatePicker onChange={setEndDate} value={endDate} />
              <span style={{ marginLeft: 20, marginRight: 10 }}>Trier par :</span>
              <Select
                options={options}
                defaultValue={options[0]}
                onChange={({ value }) => setSortRecos(value)}
                value={options.find(o => o.value === sortRecos)}
                style={{ zIndex: 5 }}
              />
            </div>
            {!!recommendationsMembers && <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: 5, marginTop: 20, height: 400, overflow: 'scroll', boxShadow: '5px 5px 10px #dddddd', position: 'relative' }}>
              <div style={{ width: '100%', backgroundColor: '#fefefe', height: 40, display: 'flex', alignItems: 'center', padding: 10, position: 'sticky', top: 0, boxShadow: '5px 5px 10px #dddddd44' }}>
                <span style={{ flex: .2 }}>Nom</span>
                <span style={{ flex: .13 }}>Envoyées</span>
                <span style={{ flex: .13 }}>Reçues</span>
                <span style={{ flex: .13 }}>Envoyées en interne</span>
                <span style={{ flex: .13 }}>Reçues en interne</span>
                <span style={{ flex: .13 }}>CA généré (HT)</span>
                <span style={{ flex: .13 }}>CA reçu (HT)</span>
              </div>
              {recommendationsMembers.sort((a, b) => b[sortRecos] - a[sortRecos]).map((r) => (
                <div key={r.name}  style={{ width: '100%', backgroundColor: '#fefefe', height: 40, display: 'flex', alignItems: 'center', padding: 10, borderBottomWidth: 1, borderBottomColor: '#00000011', borderBottomStyle: 'solid' }}>
                  <span style={{ flex: .25, height: '100%', display: 'flex', alignItems: 'center' }}>
                    <img src={r.picture_url} style={{ height: '100%', aspectRatio: 1, borderRadius: '100%', marginRight: 10, objectFit: 'cover' }}/>
                    {r.name}
                  </span>
                  <span style={{ flex: .13 }}>{r.given}</span>
                  <span style={{ flex: .13 }}>{r.received}</span>
                  <span style={{ flex: .13 }}>{Math.round(isNaN(r.givenInternal / r.given) ? 0 : (r.givenInternal / r.given) * 100)}%</span>
                  <span style={{ flex: .13 }}>{Math.round(isNaN(r.receivedInternal / r.received) ? 0 : (r.receivedInternal / r.received) * 100)}%</span>
                  <span style={{ flex: .13 }}>{r.givenAmount} €</span>
                  <span style={{ flex: .13 }}>{r.receivedAmount} €</span>
                </div>
              ))}
            </div>}
          </div>

          <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 40, marginTop: 80 }}>MEMBRES</p>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <BarChart
              title={'Membres par sphère'}
              data={data.membersSpheres}
              useData={[{
                fill: true,
                key: 'effectif',
                type: 'monotone',
                color: '#F78B14',
                strokeDasharray: false,
                tooltipValue: 'Membres'
              }]}
              showXAxis={true}
              showGrid={true}
            />
            <LineChart
              title={'Othrs et Membres de réseaux'}
              data={data?.membersNetworks?.map((m) => ({ ...m, name: monthsNames[parseInt(m.name) - 1]?.slice(0, 3) }))}
              useData={[{
                fill: true,
                key: 'users',
                color: '#CD004B',
                strokeDasharray: false,
                tooltipValue: 'Othrs'
              }, {
                fill: true,
                key: 'networkMembers',
                color: '#F78B14',
                strokeDasharray: false,
                tooltipValue: 'Membres d\'un réseau',
              }]}
              showXAxis={true}
              showGrid={true}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20, marginTop: 80 }}>
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>EVENTS</p>
            <div>
              <Select
                options={[
                  { value: 'day', label: 'Par jour' },
                  { value: 'month', label: 'Par mois' },
                ]}
                defaultValue={eventsAudience}
                onChange={(e) => setEventsAudience(e)}
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <LineChart
              title={'Taux de remplissage'}
              data={data.eventsFills?.map((m) => ({ ...m, name: monthsNames[parseInt(m.name) - 1]?.slice(0, 3) }))}
              useData={[{
                fill: true,
                key: 'frequency',
                color: '#CD004B',
                strokeDasharray: false,
                tooltipValue: 'Mon réseau'
              }, {
                fill: true,
                key: 'avgFrequency',
                color: '#F78B14',
                strokeDasharray: false,
                tooltipValue: 'Taux de remplissage moyen',
              }]}
              showXAxis={true}
              showGrid={true}
            />
            <BarChart
              title={'Audience'}
              data={eventsAudienceData}
              useData={[{
                fill: true,
                key: 'audience',
                type: 'monotone',
                color: '#CD004B',
                strokeDasharray: false,
                tooltipValue: 'Effectif'
              }]}
              showXAxis={true}
              showGrid={true}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20, marginTop: 80 }}>
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>VISIBILITÉ</p>
          <div>
            <Select
              options={[
                { value: 'day', label: 'Par jour' },
                { value: 'month', label: 'Par mois' },
              ]}
              defaultValue={networkVisibility}
              onChange={(e) => setNetworkVisibility(e)}
            />
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <BarChart
            title={'Top 10 des réseaux'}
            data={data.visibilityRanking}
            useData={[{
              fill: true,
              key: 'othrs',
              type: 'monotone',
              color: '#CD004B',
              strokeDasharray: false,
              tooltipValue: 'Effectif'
            }]}
            showXAxis={true}
            showGrid={true}
          />
          <BarChart
            title={'Vues de votre page'}
            data={getNetworkVisibilityTime()}
            useData={[{
              fill: true,
              key: 'audience',
              type: 'monotone',
              color: '#F78B14',
              strokeDasharray: false,
              tooltipValue: 'Effectif'
            }]}
            showXAxis={true}
            showGrid={true}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Stats;
