import React from 'react';

import './styles.css';

import UserSvg from '../../assets/user.svg';
import { REACT_APP_APP_URL } from '../../config';

const PageHeader = ({ relativeWidth, profile, icon, title, buttons }) => {
  return (
    <div style={{ flex: 1, width: relativeWidth, boxSizing: 'border-box', paddingLeft: relativeWidth * 0.03, paddingRight: relativeWidth * 0.03, backgroundColor: '#F2F2F2', zIndex: 100, position: 'fixed', display: 'flex', height: 115, fontSize: 24, fontWeight: 'bolder', flexDirection: 'row', alignItems: 'center' }}>
      {title}
      {
        relativeWidth > 1065 && buttons && buttons.map((b, i) => (
          <button
            key={i}
            onClick={b.active && b.onClick}
            style={{ marginLeft: i === 0 ? 40 : 25 }}
            className={b.active ? 'header-button' : 'header-button-disabled'}
          >
            {b.title}
          </button>
        ))
      }
      {
        profile && profile.user?.picture_url && (
          <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img
              alt="user"
              src={profile.user?.picture_url || UserSvg}
              onClick={() => window.location.href = REACT_APP_APP_URL }
              style={{ cursor: 'pointer', width: 45, height: 45, borderRadius: 5, objectFit: 'cover' }}
            />
          </div>
        )
      }
    </div>
  );
};

export default PageHeader;
