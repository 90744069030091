import './styles.css';

const Multicolor = ({ text, width = 100, height = 50 }) => (
  <div className="hollow-background multicolor-background" style={{ width, height, borderRadius: 5, display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '100%', textAlign: 'center', color: 'white', borderRadius: 12, fontSize: 16, fontWeight: 600 }}>
      {text}
    </div>
  </div>
);

export default Multicolor;
