import Select from 'react-select';
import moment from 'moment/moment';
import { useContext, useEffect, useState } from 'react';

import ModalContainer from './ModalContainer';
import MulticolorButton from '../Buttons/Multicolor';
import { toastError, toastSuccess } from '../../services/toast';
import { addMember, updateMember, getGroupsWithMembersRight } from '../../services/api';
import { GlobalContext } from '../../contexts/global';

const MemberForm = ({ setRefreshing, type = 'add', setModalOpen, member = {} }) => {
  const { parentNetworkId } = useContext(GlobalContext);
  const [groups, setGroups] = useState([]);

  const [job, setJob] = useState(member.job);
  const [email, setEmail] = useState(member.email);
  const [phone, setPhone] = useState(member.phone);
  const [company, setCompany] = useState(member.company);
  const [lastname, setLastname] = useState(member.lastname);
  const [firstname, setFirstname] = useState(member.firstname);
  const [birthdate, setBirthdate] = useState(member.birthdate);
  const [selectedGroup, setSelectedGroup] = useState({
    value: member.network_id,
    label: member.group,
  });

  const loadResources = async () => {
    const fetchedGroups = await getGroupsWithMembersRight(parentNetworkId);
    setGroups(fetchedGroups);
  };

  useEffect(() => {
    loadResources();
  }, []);

  const onSubmit = async () => {
    if (type === 'add') {
      try {
        await addMember(parentNetworkId, {
          job,
          email,
          phone,
          company,
          lastname,
          firstname,
          birthdate,
          networkId: selectedGroup.value,
        });
        setModalOpen(false);
        if (setRefreshing) {
          setRefreshing(true);
        }
        toastSuccess('Membre ajouté !');
      } catch (err) {
        console.log('err', err);
        toastError(err?.response?.data?.message?.split(':')?.[1] || 'Une erreur est survenue.');
      }
    }
    if (type === 'edit') {
      try {
        await updateMember(parentNetworkId, member.id, {
          job,
          email,
          phone,
          company,
          lastname,
          firstname,
          birthdate,
          networkId: selectedGroup.value,
        });
        setModalOpen(false);
        if (setRefreshing) {
          setRefreshing(true);
        }
        toastSuccess('Membre modifié !');
      } catch (err) {
        toastError('Une erreur est survenue.');
      }
    }
  };

  return (
    <form>
      <ModalContainer setModalOpen={setModalOpen}>
        <div style={{ margin: '5% 10% 5% 10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
            <label className="user-form-edit-label">
              Prénom
            </label>
            <input type="text" name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} className="input hollow-background" />
            <label className="user-form-edit-label">
              Métier
            </label>
            <input type="text" name="job" value={job} onChange={(e) => setJob(e.target.value)} className="input hollow-background" />
            <label className="user-form-edit-label">
              Date de naissance
            </label>
            <input type="text" name="birthdate" placeholder={birthdate && moment(birthdate).format('DD/MM/YYYY')} onChange={(e) => setBirthdate(e.target.value)} className="input hollow-background" />
            <label className="user-form-edit-label">
              Email
            </label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input hollow-background" />
          </div>
          <div style={{ width: '45%', display: 'flex', flexDirection: 'column' }}>
            <label className="user-form-edit-label">
              Nom
            </label>
            <input type="text" name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} className="input hollow-background" />
            <label className="user-form-edit-label">
              Entreprise
            </label>
            <input type="text" name="company" value={company} onChange={(e) => setCompany(e.target.value)} className="input hollow-background" />
            <label className="user-form-edit-label">
              Téléphone
            </label>
            <input type="number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="input hollow-background" />
            <div className="user-form-label">
              Groupe
            </div>
            {
              groups.length > 0 && (
                <Select
                  value={selectedGroup}
                  defaultValue={selectedGroup}
                  placeholder="Selectioner un groupe"
                  onChange={(e) => {
                    console.log(e);
                    setSelectedGroup(e);
                  }}
                  options={groups.map((group) => ({ value: group.id, label: group.name }))}
                />
              )
            }
          </div>
        </div>
        <div style={{ margin: '2.5% 0px 10% 10%' }} onClick={onSubmit}>
          <MulticolorButton width={'90%'} height={50} text={type === 'add' ? 'Ajouter' : 'Modifier'} />
        </div>
      </ModalContainer>
    </form>
  );
};

export default MemberForm;
