import deleteSvg from '../../assets/delete.svg';

const ModalContainer = ({ Header, children, setModalOpen }) => (
  <div style={{ width: '100%' }}>
    <div style={{ width: '90%', display: 'flex', alignItems: 'center', padding: '2.5% 5% 2.5% 5%', backgroundColor: '#DBDBDB' }}>
      {Header && <Header />}
      <button style={{ marginLeft: 'auto', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }} onClick={() => setModalOpen(false)}>
        <img src={deleteSvg} style={{ width: 25, height: 25, objectFit: 'cover' }} alt="delete-icon" />
      </button>
    </div>
    <div style={{ width: '100%', backgroundColor: 'white' }}>
      {children}
    </div>
  </div>
);

export default ModalContainer;
