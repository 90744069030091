import React, { useContext, useState } from 'react';

import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/global';
import useWindowSize from '../../components/WindowSize';
import './style.css';
import { createAccount, getAccounts } from '../../services/api';

const Billing = () => {
  const { height } = useWindowSize();
  const { parentNetworkId, allGroups } = useContext(GlobalContext);
  const [ groups, setGroups ] = useState(null);

  const loadGroups = async () => {
    const { data } = await getAccounts(parentNetworkId, allGroups);
    setGroups(data);
  };

  const handleStripeButton = async (groupId) => {
    const { data } = await createAccount(parentNetworkId, groupId);
    window.open(data.url, '_blank', 'noreferrer');
  };

  return (
    <Layout
      useChildrenStyle={false}
      loadResource={loadGroups}
      pageHeaderTitle="Ma facturation"
    >
      <div className="default-container" style={{ height: height * 0.1, alignItems: 'center', marginBottom: 0 }}>
        {groups?.map(({ group, account }) => (
          <div key={group.id} className="group-line">
            <img src={group.logo_url} alt={'group-logo'} className="group-logo"/>
            <span>{group.name}</span>
            {account?.charges_enabled ? (
              <span className="stripe-activated">Compte activé</span>
            ) : (
              <div className="stripe-button" onClick={() => handleStripeButton(group.id)}>
                <span>Configurer la facturation</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Billing;
