import {
  Tooltip,
  CartesianGrid,
  Bar,
  Line,
  YAxis,
  XAxis,
  ResponsiveContainer,
  ComposedChart,
  Legend,
  BarChart,
  Area
} from 'recharts';

import CustomTooltip from './Tooltip';

const StackedBarChart = ({ title, data, useData, showGrid, showYAxis, showXAxis }) => {
  return (
    <div style={{ width: '46%', backgroundColor: 'white', padding: 15, borderRadius: 10, boxShadow: '5px 5px 10px #dddddd' }}>
      <p style={{ marginTop: 0 }}>{title}</p>
      <div style={{ height: 300, width: '100%', marginTop: 20 }}>
        <ResponsiveContainer>
          <ComposedChart data={data} height={280}>
            {showGrid && <CartesianGrid stroke={'#dadada'} strokeWidth={1} vertical={false} />}
            {showYAxis && <YAxis axisLine={false} tickLine={false} />}
            {showXAxis && <XAxis dataKey={'name'} axisLine={false} tickLine={false} />}
            <Tooltip cursor={false} content={(props) => <CustomTooltip {...props} useData={useData} />}/>
            {/*<Tooltip/>*/}
            {useData.filter((ud) => ud.type === 'line').map((ud, index) => (
              <Line type={'monotone'} key={ud.key} dataKey={ud.key} stroke={ud.color} />
            ))}
            {useData.filter((ud) => ud.type === 'bar1').map((ud, index) => { console.log('ud', ud); return (
              <Bar key={ud.key} stackId={'a'} dataKey={ud.key} fill={ud.color} barSize={20} />
            );})}
            {useData.filter((ud) => ud.type === 'bar2').map((ud, index) => { console.log('ud', ud); return (
              <Bar key={ud.key} stackId={'a'} dataKey={ud.key} fill={ud.color} barSize={20} />
            );})}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StackedBarChart;
