import { toastError, toastSuccess } from '../../services/toast';
import { addRight, removeAdmin, removeRight } from '../../services/api';

const getRightObject = (admin, type, setRefreshing, parentNetworkId) => {
  const right = admin.rights.find((r) => r.type === type);
  if (right) {
    return {
      active: true,
      onClick: async () => {
        try {
          await removeRight(parentNetworkId, right.id, admin.id, admin.groupId);
          setRefreshing(true);
          toastSuccess('Droit retiré !');
        } catch (err) {
          toastError('Une erreur est survenue.');
        }
      },
    };
  }
  return {
    active: false,
    onClick: async () => {
      try {
        await addRight(parentNetworkId, type, admin.id, admin.groupId);
        setRefreshing(true);
        toastSuccess('Droit ajouté !');
      } catch (err) {
        toastError('Une erreur est survenue.');
      }
    },
  };
};

export const formatAdminData = (admins, setRefreshing, parentNetworkId) => admins.map((admin) => ({
  group: admin.groupName,
  name: `${admin.firstname} ${admin.lastname}`,
  data: {
    pictureUrl: admin.picture_url,
    ...admin,
  },
  rightPage: getRightObject(admin, 'PAGE', setRefreshing, parentNetworkId),
  rightEvents: getRightObject(admin, 'EVENTS', setRefreshing, parentNetworkId),
  rightMedias: getRightObject(admin, 'MEDIAS', setRefreshing, parentNetworkId),
  rightAdmins: getRightObject(admin, 'ADMINS', setRefreshing, parentNetworkId),
  rightMembers: getRightObject(admin, 'MEMBERS', setRefreshing, parentNetworkId),
  rightAdhesion: getRightObject(admin, 'ADHESION', setRefreshing, parentNetworkId),
  actions: {
    canEdit: admin.canEdit,
    onDelete: async () => {
      await removeAdmin(parentNetworkId, admin.groupId, admin.id);
      window.location.reload();
    }
  }
}));
