import React, { useContext, useState } from 'react';

import tableColumns from './tableColumns';
import { getAdhesion } from '../../services/api';
import { formatMembershipsData } from './formatData';
import { GlobalContext } from '../../contexts/global';

import Table from '../../components/Table';
import Layout from '../../components/Layout';
import NoResources from '../../components/NoResources';

const Memberships = () => {
  const [adhesion, setAdhesion] = useState();
  const [refresh, setRefreshing] = useState(false);
  const { parentNetworkId, groups } = useContext(GlobalContext);

  const loadResource = async () => {
    const fetchedAdhesion = await getAdhesion(parentNetworkId, 'EXTERN', groups);
    setAdhesion(formatMembershipsData(parentNetworkId, fetchedAdhesion));
  };

  return (
    <Layout
      refresh={refresh}
      loadResource={loadResource}
      setRefreshing={setRefreshing}
      pageHeaderTitle="Demandes d’adhésion à mon réseau"
    >
      {
        adhesion?.length > 0 ? (
          <Table
            type="adhesion"
            data={adhesion}
            columns={tableColumns}
            setRefreshing={setRefreshing}
            validate={(row) => row.values.paymentStatus === 'paid'}
            rowProps={row => ({
              onClick: () => console.log(row.values),
            })}
          />
        ) : (
          <NoResources
            noResourceText="Vous n'avez pas de demande d'adhesion"
            findResourceText="Lorsque qu'une demande est effectuée vous la retrouverez ici!"
            buttons={[
              /*
              {
                title: 'Inviter un membre',
                onClick: () => {}
              }
               */
            ]}
          />
        )
      }
    </Layout>
  );
};

export default Memberships;
