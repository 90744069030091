import Select from 'react-select';

import { useContext, useState } from 'react';

import { getAccounts, getPromotionCodes } from '../../services/api';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/global';

const options = Array.from(Array(50).keys()).map((i) => ({
  value: (i + 1).toString(), label: (i + 1).toString(),
}));
const Codes = () => {
  const [ number, setNumber ] = useState(options[0]);
  const [ codes, setCodes ] = useState(null);
  const { parentNetworkId, allGroups } = useContext(GlobalContext);
  const [ groups, setGroups ] = useState(null);
  const [ group, setGroup ] = useState(null);

  const loadGroups = async () => {
    const { data } = await getAccounts(parentNetworkId, allGroups);
    setGroups(data);
    if (data.length > 0) {
      setGroup({ value: data[0].group.id.toString(), label: data[0].group.name });
    }
  };

  const getCodes = async () => {
    if (!parentNetworkId || !group || !number.value) return;
    const data = await getPromotionCodes(parentNetworkId, group.value, parseInt(number.value));
    setCodes(data.codes);
  };

  return (
    <Layout
      loadResource={loadGroups}
      useChildrenStyle={false}
      pageHeaderTitle="Codes de réduction"
    >
      <div className="default-container">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: 300 }}>
            {!!groups && <Select
              placeholder={'Groupement'}
              options={groups.map(({ group }) => ({ value: group.id.toString(), label: group.name }))}
              value={group}
              onChange={(value) => setGroup(value)}
            />}
            <div style={{ height: 10 }}/>
            <Select
              options={options}
              value={number}
              onChange={setNumber}
            />
            <div style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5, padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={getCodes}>
              <span>Générer les codes</span>
            </div>
          </div>
          {!!codes && <div style={{ marginLeft: 50, width: 200, maxHeight: 200, overflowY: 'scroll', backgroundColor: 'white', padding: 10, borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
            {codes.map((c) => <span key={c}>{c}</span>)}
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default Codes;
