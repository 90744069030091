export default [
  {
    type: 'profile',
    Header: 'Prénom/Nom',
    accessor: 'name',
  },
  {
    type: 'information-italic-grey',
    Header: 'Métier',
    accessor: 'job',
  },
  {
    type: 'information-italic-grey',
    Header: 'Groupe',
    accessor: 'group',
  },
  {
    type: 'information-italic-grey',
    Header: 'Ville',
    accessor: 'city',
  },
  {
    type: 'contribution',
    Header: 'Cotisation',
    accessor: 'contributionEndDate',
  },
  {
    type: ['contact-icon', 'edit-icon', 'delete-icon'],
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    type: 'hidden',
    accessor: 'data',
  },
];
