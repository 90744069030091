export default [
  {
    type: 'event_title',
    Header: 'Event',
    accessor: 'title',
  },
  {
    type: 'event_date',
    Header: 'Date',
    accessor: 'date',
  },
  {
    type: 'address',
    Header: 'Adresse',
    accessor: 'address',
  },
  {
    type: 'event_tickets',
    Header: 'Ventes',
    accessor: 'tickets',
  },
  {
    type: 'event_status',
    Header: 'Statut',
    accessor: 'status',
  },
  {
    type: ['consult-event-icon', 'upload-icon'],
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    type: 'link',
    accessor: 'link',
  },
  {
    type: 'hidden',
    accessor: 'data',
  },
];
