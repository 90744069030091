import Modal from 'react-modal';
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';
import { useContext, useEffect, useState } from 'react';

import MemberForm from './MemberForm';
import ModalContainer from './ModalContainer';
import MultiColorButton from '../Buttons/Multicolor';
import { GlobalContext } from '../../contexts/global';
import { toastError, toastSuccess } from '../../services/toast';
import { getMembers, getGroupsWithAdminRight, addAdmin, inviteAdmin } from '../../services/api';

const AddAdmin = ({ setModalOpen, setRefreshing }) => {
  const { parentNetworkId } = useContext(GlobalContext);
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const loadResource = async () => {
    const fetchedMembers = await getMembers(parentNetworkId);
    const fetchedGroups = await getGroupsWithAdminRight(parentNetworkId);
    setMembers(fetchedMembers.map((group) => group.users).flat());
    setGroups(fetchedGroups);
    setLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      loadResource();
    }
  }, [members, groups]);

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      {
        isLoading ? (
          <div style={{ display: 'flex', width: '100%', height: 100, alignItems: 'center', justifyContent: 'center' }}>
            <Oval width={30} height={30} color="#CD004B" secondaryColor="rgba(205,0,75,0.7)" />
          </div>
        ) : (
          <div style={{ minHeight: 400, width: '70%', marginLeft: '15%', marginTop: 25 }}>
            Membres
            <div style={{ marginTop: 10, marginBottom: 25 }}>
              <Select
                isMulti
                placeholder="Selectioner un/des membre(s)"
                onChange={(e) => setSelectedUsers(e)}
                options={members
                  .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
                  .map((member) => ({ value: member.id, label: `${member.firstname} ${member.lastname}` }))
                }
              />
            </div>
            Groupes
            <div style={{ marginTop: 10 }}>
              <Select
                isMulti
                placeholder="Selectioner un/des groupe(s)"
                onChange={(e) => setSelectedGroups(e)}
                options={groups.map((group) => ({ value: group.id, label: group.name }))}
              />
            </div>
            <div
              style={{ marginTop: 75 }}
              onClick={async () => {
                if (selectedUsers && selectedGroups) {
                  try {
                    await addAdmin(parentNetworkId, selectedGroups.map((g) => g.value), selectedUsers.map((u) => u.value));
                    toastSuccess('Admin ajouté !');
                    if (setRefreshing) setRefreshing(true);
                  } catch (err) {
                    toastError();
                  }
                }
              }}
            >
              <MultiColorButton width={200} text="Ajouter" />
            </div>
          </div>
        )
      }
    </ModalContainer>
  );
};

const InviteAdmin = ({ setModalOpen, setRefreshing }) => {
  const { parentNetworkId } = useContext(GlobalContext);
  const [groups, setGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [email, setEmail] = useState('');

  const loadResource = async () => {
    const fetchedGroups = await getGroupsWithAdminRight(parentNetworkId);
    setGroups(fetchedGroups);
    setLoading(false);
  };

  useEffect(() => {
    loadResource();
  }, []);

  return (
    <ModalContainer setModalOpen={setModalOpen}>
      {
        isLoading ? (
          <div style={{ display: 'flex', width: '100%', height: 100, alignItems: 'center', justifyContent: 'center' }}>
            <Oval width={30} height={30} color="#CD004B" secondaryColor="rgba(205,0,75,0.7)" />
          </div>
        ) : (
          <div style={{ minHeight: 400, width: '70%', marginLeft: '15%', marginTop: 50 }}>
            Email
            <div style={{ marginTop: 10, marginBottom: 25, width: '100%' }}>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%' }} className="input hollow-background" />
            </div>
            Groupes
            <div style={{ marginTop: 10 }}>
              <Select
                isMulti
                placeholder="Selectioner un/des groupe(s)"
                onChange={(e) => setSelectedGroups(e)}
                options={groups.map((group) => ({ value: group.id, label: group.name }))}
              />
            </div>
            <div
              style={{ marginTop: 50 }}
              onClick={async () => {
                if (selectedGroups && email) {
                  try {
                    await inviteAdmin(parentNetworkId, selectedGroups.map((g) => g.value), email);
                    toastSuccess('Invitation envoyée !');
                    if (setRefreshing) setRefreshing(true);
                  } catch (err) {
                    toastError();
                  }
                }
              }}
            >
              <MultiColorButton width={200} text="Ajouter" />
            </div>
          </div>
        )
      }
    </ModalContainer>
  );
};

const AddMember = ({ setModalOpen, setRefreshing }) => (
  <MemberForm setRefreshing={setRefreshing} setModalOpen={setModalOpen} type="add" />
);

const HeaderModal = ({ modalIsOpen, setRefreshing, setModalOpen, type }) => {
  let modalWidth = '100%';
  if (type === 'add-admin' || type === 'add-member' || type === 'invite-admin') {
    modalWidth = '40%';
  }
  if (type === 'request') {
    modalWidth = '60%';
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Example Modal"
      onRequestClose={() => setModalOpen(false)}
      style={{
        content: {
          top: '50%',
          left: '50%',
          padding: 0,
          bottom: 'auto',
          borderRadius: 5,
          width: modalWidth,
          overflow: 'hidden',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {
          zIndex: 200,
          background: 'rgba(0, 0, 0, 0.4)'
        },
      }}
    >
      {
        type === 'add-admin' && (
          <AddAdmin setRefreshing={setRefreshing} setModalOpen={setModalOpen} />
        )
      }
      {
        type === 'invite-admin' && (
          <InviteAdmin setRefreshing={setRefreshing} setModalOpen={setModalOpen} />
        )
      }
      {
        type === 'add-member' && (
          <AddMember setRefreshing={setRefreshing} setModalOpen={setModalOpen} />
        )
      }
    </Modal>
  );
};

export default HeaderModal;
