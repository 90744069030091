import { Fragment } from 'react';

import Router from './Router';
import { ToastContainer } from 'react-toastify';

import { GlobalProvider } from './contexts/global';
import TableColumnTooltip from './components/TableColumnTooltip';

function App() {
  return (
    <Fragment>
      <ToastContainer
        draggable
        rtl={false}
        pauseOnHover
        closeOnClick
        autoClose={5000}
        pauseOnFocusLoss
        newestOnTop={false}
        position="top-right"
        hideProgressBar={false}
      />
      <TableColumnTooltip />
      <GlobalProvider>
        <Router />
      </GlobalProvider>
    </Fragment>
  );
}

export default App;
