import moment from 'moment/moment';

import { REACT_APP_API_URL, REACT_APP_APP_URL } from '../../config';
import { acceptAdhesion, addContribution, rejectAdhesion, removeMember } from '../../services/api';

const getStatusText = (status) => {
  if (status === 'rejected') {
    return 'Refusé';
  }
  if (status === 'accepted') {
    return 'Accepté';
  }
  if (status === 'pending') {
    return 'En attente';
  }
  if (status === 'paid') {
    return 'Membre ajouté!';
  }
  if (status === 'waiting-payment') {
    return 'Paiement en attente';
  }
};

export const formatInternAdhesionData = (adhesion, parentNetworkId) => {
  const pending = adhesion.filter((a) => a.status === 'pending');
  const waitingPayment = adhesion.filter((a) => a.status === 'waiting-payment');
  const paid = adhesion.filter((a) => a.status === 'paid');
  const rejected = adhesion.filter((a) => a.status === 'rejected');
  const formatedAdhesion = [...pending, ...waitingPayment, ...paid, ...rejected].map((a) => ({
    name: `${a.user.firstname} ${a.user.lastname}`,
    groupName: a.network.name,
    creationDate: moment(a.createdAt).format('DD/MM/YYYY'),
    status: getStatusText(a.status),
    paymentStatus: (a.status !== 'accepted' && a.status !== 'rejected') && a.status,
    data: {
      pictureUrl: a.user.picture_url,
      request: a.message,
      adhesionStatus: a.status,
      ...a.user,
    },
    actions: {
      canEdit: a.canEdit,
      disabled: a.status !== 'pending',
      onAccept: async () => {
        await acceptAdhesion(parentNetworkId, a.id);
        window.location.reload();
      },
      onDelete: async () => {
        await rejectAdhesion(parentNetworkId, a.id);
        window.location.reload();
      }
    }
  }));
  return formatedAdhesion.filter((v) => v.data.adhesionStatus === 'pending').filter((v, i, a) => a.findIndex(v2 => (v2.data.id === v.data.id)) === i);
};

export const formatMembersData = (members, parentNetworkId) => {
  const pastWeek = new Date();
  pastWeek.setDate(pastWeek.getDate() - 7);
  return members.map((group) => [...group.users, ...group.unknownMembers].map((member) => ({
    id: member.id,
    job: member.job,
    group: group.name,
    city: member.address?.city,
    name: `${member.firstname} ${member.lastname}`,
    contributionEndDate: member.network_contributions?.find((c) => c.current)?.until,
    data: {
      actions: {
        onDelete: async () => {
          await removeMember(parentNetworkId, member.email, group.id);
        }
      },
      isNew: new Date(member.adhesionDate) > pastWeek,
      group: group.name,
      tva: group.tax_percent,
      pictureUrl: member.picture_url,
      addContribution: async (price, type, adhesionDate) => {
        await addContribution(parentNetworkId, member.id, price, type, group.id,`${REACT_APP_API_URL}/application/groups?id=${group.id}&status=success`, `${REACT_APP_API_URL}/application/groups?id=${group.id}&status=error`,member.network_id ? 'UNKNOWN' : 'USER', adhesionDate);
      },
      pendingContribution: member.network_contributions?.find((c) => c.status === 'waiting-payment'),
      ...member,
    },
    actions: [{
      active: true,
      onClick: () => {},
    }, {
      active: group.canEdit && !!member.network_id,
      onClick: () => {},
    }, {
      active: group.canEdit,
      onClick: async () => {
        await removeMember(parentNetworkId, member.email, group.id);
      },
    }],
  }))).flat().sort((a, b) => {
    if (a.data.isNew && b.data.isNew) {
      return 0;
    }
    if (a.data.isNew) {
      return -1;
    }
    if (b.data.isNew) {
      return 1;
    }
  });
};
