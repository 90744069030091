import { Tooltip, CartesianGrid, BarChart, Bar, YAxis, XAxis, ResponsiveContainer } from 'recharts';

import CustomTooltip from './Tooltip';

const BarChartPie = ({ title, data, useData, showGrid, showYAxis, showXAxis }) => {
  return (
    <div style={{ width: '46%', backgroundColor: 'white', padding: 15, borderRadius: 10, boxShadow: '5px 5px 10px #dddddd' }}>
      <p style={{ marginTop: 0 }}>{title}</p>
      <div style={{ height: 300, width: '100%', marginTop: 20 }}>
        <ResponsiveContainer>
          <BarChart data={data} height={280}>
            {showGrid && <CartesianGrid stroke={'#dadada'} strokeWidth={1} vertical={false} />}
            {showYAxis && <YAxis axisLine={false} tickLine={false} />}
            {showXAxis && <XAxis dataKey={'name'} tick={false} axisLine={false} tickLine={false} />}
            {/*<Tooltip cursor={false} content={(props) => <CustomTooltip {...props} useData={useData} />}/>*/}
            <Tooltip/>
            {useData.map((ud, index) => (
              <Bar key={index} dataKey={ud.key} fill={ud.color} label={{ position: 'bottom' }} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartPie;
