import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';

import LogoBlack from '../../assets/logoBlack.svg';

import { ReactComponent as PageSvg } from '../../assets/menuIcon/page.svg';
import { ReactComponent as EventSvg } from '../../assets/menuIcon/event.svg';
import { ReactComponent as MediaSvg } from '../../assets/menuIcon/media.svg';
import { ReactComponent as AdminSvg } from '../../assets/menuIcon/admin.svg';
import { ReactComponent as StatsSvg } from '../../assets/menuIcon/stats.svg';
import { ReactComponent as MemberSvg } from '../../assets/menuIcon/members.svg';
import { ReactComponent as HandleMembersSvg } from '../../assets/menuIcon/handle-members.svg';
import { ReactComponent as WalletSvg } from '../../assets/menuIcon/wallet.svg';
import { ReactComponent as PercentSvg } from '../../assets/menuIcon/percent.svg';

import { ReactComponent as GreyPage } from '../../assets/menuIconGrey/page.svg';
import { ReactComponent as GreyStat } from '../../assets/menuIconGrey/stats.svg';

import { ReactComponent as BurgundyPageSvg } from '../../assets/menuIconBurgundy/page.svg';
import { ReactComponent as BurgundyEventSvg } from '../../assets/menuIconBurgundy/event.svg';
import { ReactComponent as BurgundyMediaSvg } from '../../assets/menuIconBurgundy/media.svg';
import { ReactComponent as BurgundyAdminSvg } from '../../assets/menuIconBurgundy/admin.svg';
import { ReactComponent as BurgundyStatsSvg } from '../../assets/menuIconBurgundy/stats.svg';
import { ReactComponent as BurgundyMemberSvg } from '../../assets/menuIconBurgundy/members.svg';
import { ReactComponent as BurgundyHandleMembersSvg } from '../../assets/menuIconBurgundy/handle-members.svg';
import { ReactComponent as BurgundyWalletSvg } from '../../assets/menuIconBurgundy/wallet.svg';
import { ReactComponent as BurgundyPercentSvg } from '../../assets/menuIconBurgundy/percent.svg';

import { GlobalContext } from '../../contexts/global';

const section = [
  {
    route: '/',
    title: 'Mes Stats',
    svg: () => <StatsSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyStatsSvg style={{ width: 30 }} alt="cat-icon" />,
    greySvg: GreyStat,
  },
  {
    route: '/adhesion',
    title: 'Demandes d\'adhésion',
    svg: () => <MemberSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyMemberSvg style={{ width: 30 }} alt="cat-icon" />,
  },
  {
    route: '/members',
    title: 'Gérer mes membres',
    svg: () => <HandleMembersSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyHandleMembersSvg style={{ width: 30 }} alt="cat-icon" />,
  },
  {
    route: '/pages',
    title: 'Mes pages',
    svg: () => <PageSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyPageSvg style={{ width: 30 }} alt="cat-icon" />,
    greySvg: GreyPage,
  },
  {
    route: '/events',
    title: 'Mes Events',
    svg: () => <EventSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyEventSvg style={{ width: 30 }} alt="cat-icon" />,
  },
  {
    route: '/medias',
    title: 'Mes Medias',
    svg: () => <MediaSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyMediaSvg style={{ width: 30 }} alt="cat-icon" />,
  },
  {
    route: '/admins',
    title: 'Gérer mon équipe',
    svg: () => <AdminSvg style={{ width: 30 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyAdminSvg style={{ width: 30 }} alt="cat-icon" />,
  },
  {
    route: '/codes',
    title: 'Codes',
    svg: () => <PercentSvg style={{ width: 22 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyPercentSvg style={{ width: 22 }} alt="cat-icon" />,
  },
  {
    route: '/billing',
    title: 'Ma facturation',
    svg: () => <WalletSvg style={{ width: 28 }} alt="cat-icon" />,
    svgBurgundy: () => <BurgundyWalletSvg style={{ width: 28 }} alt="cat-icon" />,
  },
];

const Menu = ({ width, profile }) => {
  const location = useLocation();
  const { allGroups, groups, setGroups } = useContext(GlobalContext);

  return (
    <div
      style={{
        width,
        zIndex: 1,
        height: '100vh',
        position: 'fixed',
        backdropFilter: 'blur(5px)',
        backgroundColor: '#FEFEFE',
      }}
    >
      <ReactTooltip data-place="top" id="menu" backgroundColor="#CD004B" textColor="white" arrowColor="transparent" />
      <div style={{ margin: 20, height: '96%', display: 'flex', flexDirection: 'column' }}>
        <Link style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', minHeight: 65 }} to="/">
          {profile && <img src={profile?.parentNetwork?.logo_url} style={{ marginLeft: 10, width: 65, height: 65, borderRadius: 5, objectFit: 'cover' }} alt="network-logo" />}
          <img src={LogoBlack} style={{ width: 100, marginLeft: 20 }} alt="logo" />
        </Link>
        <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}>
          {
            section.map((s, index) => (
              s.disabled ? (
                <div data-tip="Arrive prochainement!" data-for="menu" style={{ cursor: 'pointer', textDecoration: 'none', color: 'black', marginLeft: 10, marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div className="grey" style={{ marginLeft: 20 }}>
                    {s.title}
                  </div>
                </div>
              ) : (
                <Link key={index} to={s.route} style={{ textDecoration: 'none', color: 'black', marginLeft: 10, marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ width: 30 }}>
                    {
                      location.pathname === s.route ? (
                        s.svgBurgundy()
                      ) : (
                        s.svg()
                      )
                    }
                  </div>
                  <div className={`${location.pathname === s.route ? 'burgundy' : 'black'}`} style={{ marginLeft: 15, fontSize: 15 }}>
                    {s.title}
                  </div>
                </Link>
              )
            ))
          }
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={{ marginBottom: 5, marginLeft: 10 }}>
            Groupes
          </div>
          {
            allGroups && allGroups.length > 0 && (
              <Select
                maxMenuHeight={95}
                isMulti
                placeholder="Tous les groupes"
                defaultValue={groups || undefined}
                onChange={(e) => setGroups(e)}
                options={allGroups.map((group) => ({ value: group.id, label: group.name }))}
              />
            )
          }
        </div>
        <div style={{ marginTop: 'auto', marginLeft: 10 }} className="grey">
          © Othr 2022
        </div>
      </div>
    </div>
  );
};

export default Menu;
