import { toast } from 'react-toastify';

function toastSuccess(message) {
  return (
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  );
}

function toastError(message = 'Une erreur est survenue.') {
  return (
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  );
}

export {
  toastSuccess,
  toastError,
};
