import './styles.css';

const prettyValue = (value) => {
  if (typeof value === 'number' && !Number.isInteger(value)) {
    return value.toFixed(2);
  } else {
    return value;
  }
};

const CustomTooltip = ({ useData, payload, label, active }) => {
  if (active) {
    return (
      <div className="tooltip-container">
        {
          useData.map((ud, index) => (
            <div className="tooltip-label" key={index}>
              {`${ud.tooltipValue}: ${prettyValue(payload?.find(p => p.dataKey === ud.key)?.value)}`}
            </div>
          ))
        }
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
