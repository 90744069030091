import ReactDatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import styles from './style.module.scss';
import './style.css';

const DatePicker = (props) => {
  return (
    <ReactDatePicker
      format={'dd/MM/yyyy'}
      onChange={props.onChange}
      value={props.value}
      className={styles.calendar}
      calendarClassName={styles.calendarPopup}
      maxDate={new Date()}
      showLeadingZeros={true}
      clearIcon={null}
    />
  );
};

export default DatePicker;
