import { useLayoutEffect, useState, useCallback } from 'react';

const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  let w = 0;
  let h = 0;
  const handleSize = useCallback(() => {
    if (parseInt(w % 100 / 10) !== parseInt(window.innerWidth % 100 / 10)) {
      w = window.innerWidth;
      setWidth(window.innerWidth);
    }
    if (parseInt(h % 100 / 10) !== parseInt(window.innerHeight % 100 / 10)) {
      h = window.innerHeight;
      setHeight(window.innerHeight);
    }
  }, [width, height]);

  useLayoutEffect(() => {
    handleSize();
    window.addEventListener('resize', handleSize);

    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return { width, height };
};

export default useWindowSize;
