const TableFilters = ({ value, setValue, filters, onFilterChange }) => {
  return (
    <div style={{ width: '100%', height: 60 }}>
      <div style={{ width: '100%', display: 'flex', marginLeft: '-3%', paddingLeft: '3%', marginTop: -40, flexDirection: 'row', height: 80, alignItems: 'center', position: 'fixed', background: '#F2F2F2' }}>
        {
          filters && filters.map((filter, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', marginLeft: index === 0 ? 0 : 30 }}>
              <div className="grey" style={{ marginRight: 5, height: 20 }}>
                {filter.name}
              </div>
              <select
                value={value}
                style={{ backgroundColor: 'transparent', border: 'none', marginTop: 2.5 }}
                onChange={(e) => {
                  setValue(e.target.value);
                  onFilterChange(e.target.value);
                }}
              >
                <option value="placeholder">
                  <h4 className="bold">
                    {filter.placeholder}
                  </h4>
                </option>
                {
                  filter.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      <h4 className="bold">
                        {option.name}
                      </h4>
                    </option>
                  ))
                }
              </select>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default TableFilters;
