import moment from 'moment';
import 'moment/locale/fr';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Pages from './screens/Pages';
import Stats from './screens/Stats';
import Medias from './screens/Medias';
import Events from './screens/Events';
import Admins from './screens/Admins';
import Members from './screens/Members';
import Memberships from './screens/Memberships';
import Billing from './screens/Billing';
import Codes from './screens/Codes';

moment.locale('fr');

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Stats />} />
      <Route path="pages" element={<Pages />} />
      <Route path="admins" element={<Admins />} />
      <Route path="medias" element={<Medias />} />
      <Route path="events" element={<Events />} />
      <Route path="members" element={<Members />} />
      <Route path="adhesion" element={<Memberships />} />
      <Route path="billing" element={<Billing />} />
      <Route path="codes" element={<Codes />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
