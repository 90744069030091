import React, { useContext, useEffect, useState } from 'react';

import Layout from '../../components/Layout';
import { REACT_APP_APP_URL } from '../../config';
import { GlobalContext } from '../../contexts/global';
import useWindowSize from '../../components/WindowSize';
import { getGroups, getPageRight } from '../../services/api';

const Pages = () => {
  const { height } = useWindowSize();
  const { parentNetworkId } = useContext(GlobalContext);

  const [groups, setGroups] = useState([]);
  const [canEditPage, setCanEditPage] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(undefined);

  const loadGroups = async () => {
    const data = await getGroups(parentNetworkId);
    setGroups(data);
    const canEdit = await getPageRight(parentNetworkId);
    setCanEditPage(canEdit);
  };

  useEffect(() => setSelectedGroup(undefined), []);

  const IframeContainer = () => (
    <div id="test" style={{ marginTop: 115, height: height - 115, overflow: 'scroll' }}>
      <iframe
        width="100%"
        title="my_page"
        id="pageIframe"
        sandbox="allow-scripts"
        height={height - 115}
        onClick={() => console.log('ICI')}
        src={`${REACT_APP_APP_URL}/groups/${selectedGroup.name}-${selectedGroup.id}`}
        style={{ overflow: 'visible', borderWidth: 0, pointerEvents: 'none' }}
      />
    </div>
  );

  const ChooseGroup = () => (
    <div style={{ display: 'flex', minHeight: '50vh', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
      {
        groups.map((group, index) => (
          <div
            key={index}
            onClick={() => window.open(`${REACT_APP_APP_URL}/groups?id=${group.id}`)}
            style={{ display: 'flex', cursor: 'pointer', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 20 }}
          >
            <img src={group.logo_url} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 10, marginBottom: 5 }} alt="group-logo" />
            {group.name}
          </div>
        ))
      }
    </div>
  );

  return (
    <Layout
      useChildrenStyle={false}
      loadResource={loadGroups}
      pageHeaderTitle="Mes pages"
      pageHeaderButtons={selectedGroup && [
        { title: 'Toutes les Pages', onClick: () => setSelectedGroup(undefined), active: true },
        { title: 'Modifier la Page', onClick: () => window.open(`${REACT_APP_APP_URL}/groups?id=${selectedGroup?.id}`), active: canEditPage },
      ]}
    >
      {
        selectedGroup ? (
          <IframeContainer />
        ) : (
          <div className="default-container" style={{ height: height * 0.1, alignItems: 'center', marginBottom: 0 }}>
            <ChooseGroup />
          </div>
        )
      }
    </Layout>
  );
};

export default Pages;
