import moment from 'moment/moment';
import { rejectAdhesion, sendFirstContribution } from '../../services/api';
import { REACT_APP_APP_URL } from '../../config';

const getStatusText = (status) => {
  if (status === 'rejected') {
    return 'Refusé';
  }
  if (status === 'accepted') {
    return 'Accepté';
  }
  if (status === 'pending') {
    return 'En attente';
  }
  if (status === 'paid') {
    return 'Membre ajouté!';
  }
  if (status === 'waiting-payment') {
    return 'Paiement en attente';
  }
};

export const formatMembershipsData = (parentNetworkId, adhesion) => {
  const pending = adhesion.filter((a) => a.status === 'pending');
  const waitingPayment = adhesion.filter((a) => a.status === 'waiting-payment');
  const paid = adhesion.filter((a) => a.status === 'paid');
  const rejected = adhesion.filter((a) => a.status === 'rejected');
  return [...pending, ...waitingPayment, ...paid, ...rejected].map((a) => ({
    name: `${a.user.firstname} ${a.user.lastname}`,
    groupName: a.network.name,
    creationDate: moment(a.createdAt).format('DD/MM/YYYY'),
    status: getStatusText(a.status),
    paymentStatus: (a.status !== 'accepted' && a.status !== 'rejected') && a.status,
    data: {
      sendContributionLink: async (price) => {
        await sendFirstContribution(parentNetworkId, a.id, price,`${REACT_APP_APP_URL}/contribution/payment?networkId=${a.network.id}&type=success`, `${REACT_APP_APP_URL}/contribution/payment?networkId=${a.network.id}&type=cancel`);
        window.location.reload();
      },
      tva: a.network.tax_percent,
      pictureUrl: a.user.picture_url,
      request: a.message,
      ...a.user,
    },
    actions: {
      canEdit: a.canEdit,
      disabled: a.status !== 'pending',
      /*
              onAccept: async () => {
        await acceptAdhesion(adhesion.id);
        window.location.reload();
      },
       */
      onDelete: async () => {
        await rejectAdhesion(parentNetworkId, a.id);
        window.location.reload();
      }
    }
  })).filter((v, i, a) => a.findIndex(v2 => (v2.data.id === v.data.id)) === i);
};
