import axios from 'axios';
import { REACT_APP_API_URL } from '../config';
import moment from 'moment';

const baseURL = REACT_APP_API_URL;

const requester = axios.create({
  baseURL,
});

requester.interceptors.request.use(function (config) {
  config.headers.authorization =  localStorage.getItem('AUTH_TOKEN');
  return config;
});

export const getAccessToken = async (code) => {
  const { data } = await requester.get(`/auth/access_token?auth_code=${code}`);
  if (data.token) {
    requester.defaults.headers.common['authorization'] = data.token;
    await localStorage.setItem('AUTH_TOKEN', data.token);
  }
  return data;
};

export const getProfile = async (parentNetworkId) => {
  const { data } = await requester.get(`/network_admin/${parentNetworkId}/profile`);
  return data;
};

export const getGroupsWithAdminRight = async (parentNetworkId) => {
  const { data } = await requester.get(`/network_admin/${parentNetworkId}/groups/admin-right`);
  return data;
};

export const getGroupsWithMembersRight = async (parentNetworkId) => {
  const { data } = await requester.get(`/network_admin/${parentNetworkId}/groups/members-right`);
  return data;
};

export const getGroups = async (parentNetworkId) => {
  const { data } = await requester.get(`/network_admin/${parentNetworkId}/groups`);
  return data;
};

export const getMembers = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/members?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/members`);
  }
  return req.data;
};

export const addMember = async (parentNetworkId, body) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/members`, body);
  return data;
};

export const removeMember = async (parentNetworkId, email, networkId) => {
  const { data } = await requester.delete(`/network_admin/${parentNetworkId}/members?email=${email}&networkId=${networkId}`);
  return data;
};

export const updateMember = async (parentNetworkId, id, body) => {
  const { data } = await requester.patch(`/network_admin/${parentNetworkId}/members/${id}`, body);
  return data;
};

export const getAdhesion = async (parentNetworkId, type, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/adhesions?type=${type}&groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/adhesions?type=${type}`);
  }
  return req.data;
};

export const acceptAdhesion = async (parentNetworkId, id) => {
  const { data } = await requester.put(`/network_admin/${parentNetworkId}/adhesions/${id}/accept`);
  return data;
};

export const rejectAdhesion = async (parentNetworkId, id) => {
  const { data } = await requester.put(`/network_admin/${parentNetworkId}/adhesions/${id}/reject`);
  return data;
};

export const sendFirstContribution = async (parentNetworkId, id, price, successUrl, cancelUrl) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/adhesions/${id}/contribution`, { price, successUrl, cancelUrl });
  return data;
};

export const addContribution = async (parentNetworkId, id, price, type, groupId, successUrl, cancelUrl, userType, adhesionDate) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/members/${id}/contribution`, {
    price,
    type,
    groupId,
    successUrl,
    cancelUrl,
    userType,
    adhesionDate,
  });
  return data;
};

export const getEvents = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/events?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/events`);
  }
  return req.data;
};

export const downloadParticipants = async (parentNetworkId, eventId) => {
  const { data } = await requester.get(`/network_admin/${parentNetworkId}/events/participants?eventId=${eventId}`);
  return data;
};

export const getMedias = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/mediums?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/mediums`);
  }
  return req.data;
};

// ADMINS

export const getAdmins = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/admins?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/admins`);
  }
  return req.data;
};

export const inviteAdmin = async (parentNetworkId, groups, email) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/admins/invite`, { groups, email });
  return data;
};

export const addAdmin = async (parentNetworkId, groups, members) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/admins`, { groups, members });
  return data;
};

export const removeAdmin = async (parentNetworkId, networkId, userId) => {
  const { data } = await requester.delete(`/network_admin/${parentNetworkId}/admins?networkId=${networkId}&userId=${userId}`);
  return data;
};

// RIGHTS

export const addRight = async (parentNetworkId, type, userId, networkId) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/admins/rights`, { userId, networkId, type });
  return data;
};

export const removeRight = async (parentNetworkId, rightId, userId, networkId) => {
  const { data } = await requester.delete(`/network_admin/${parentNetworkId}/admins/rights/${rightId}`, { userId, networkId });
  return data;
};

export const getAdminsRight = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/admins?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/admins`);
  }
  return req.data;
};

export const getMembersRight = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/members?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/members`);
  }
  return req.data;
};

export const getEventsRight = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/events?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/events`);
  }
  return req.data;
};

export const getMediasRight = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/medias?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/medias`);
  }
  return req.data;
};

export const getPageRight = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/page?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/rights/page`);
  }
  return req.data;
};

export const getStats = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats`);
  }
  return req.data;
};

export const getStatsMembersSpheres = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/members/spheres?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/members/spheres`);
  }
  return req.data;
};

export const getStatsMembersNetworks = async (parentNetworkId) => {
  const req = await requester.get(`/network_admin/${parentNetworkId}/stats/members/networks`);
  return req.data;
};

export const getStatsEventsFills = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/events/fills?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/events/fills`);
  }
  return req.data;
};

export const getStatsEventsAudience = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/events/audience?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/events/audience`);
  }
  return req.data;
};

export const getStatsVisibilityTime = async (parentNetworkId) => {
  const req = await requester.get(`/network_admin/${parentNetworkId}/stats/visibility/time`);
  return req.data;
};

export const getStatsVisibilityRanking = async (parentNetworkId) => {
  const req = await requester.get(`/network_admin/${parentNetworkId}/stats/visibility/ranking`);
  return req.data;
};

export const getStatsRecommendationsTime = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/time?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/time`);
  }
  return req.data;
};

export const getStatsRecommendationsSphere = async (parentNetworkId, groups = undefined) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/sphere?groupIds=${groups.map((group) => group.value).join(',')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/sphere`);
  }
  return req.data;
};

export const getStatsRecommendationsMembers = async (parentNetworkId, groups = undefined, startDate, endDate) => {
  let req;
  if (groups && groups.length > 0) {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/members?groupIds=${groups.map((group) => group.value).join(',')}&start_date=${moment(startDate).format('DD/MM/YYYY')}&end_date=${moment(endDate).format('DD/MM/YYYY')}`);
  } else {
    req = await requester.get(`/network_admin/${parentNetworkId}/stats/recommendations/members?start_date=${moment(startDate).format('DD/MM/YYYY')}&end_date=${moment(endDate).format('DD/MM/YYYY')}`);
  }
  return req.data;
};

export const getAccounts = async (parentNetworkId) => {
  return requester.get(`/network_admin/${parentNetworkId}/payments/status`);
};

export const createAccount = async (parentNetworkId, groupId) => {
  return requester.get(`/network_admin/${parentNetworkId}/payments/configure?groupId=${groupId}`);
};

export const getPromotionCodes = async (parentNetworkId, groupId, number) => {
  const { data } = await requester.post(`/network_admin/${parentNetworkId}/payments/promotion-codes?groupId=${groupId}`, {
    number
  });
  return data;
};
