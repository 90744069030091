import { REACT_APP_API_URL } from '../../config';
import { downloadParticipants, removeMember } from '../../services/api';

export const formatEventData = (events, parentNetworkId) => events.map((event) => ({
  title: event.title,
  address: event.address,
  status: 'open',
  date: {
    endDate: event.end_date,
    startDate: event.start_date,
  },
  tickets: {
    takenPlaces: event.user_tickets.length,
    totalPlaces: event.total_places,
  },
  data: {
    pictureUrl: event.picture_url,
  },
  link: {
    onClick: async () => {
      const data = await downloadParticipants(parentNetworkId, event.id);
      const blob = new Blob([data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'participants.csv';
      link.href = url;
      link.click();
    },
    text: 'Télécharger les participants',
  },
  actions: [{
    active: true,
    onClick: () => window.open(`${REACT_APP_API_URL}/application/events?id=${event.id}`),
  }, {
    value: `${REACT_APP_API_URL}/application/events?id=${event.id}`,
  }],
}));
