import React, { useContext, useState } from 'react';

import tableColumns from './tableColumns';
import { formatAdminData } from './formatData';
import { GlobalContext } from '../../contexts/global';
import { getAdmins, getAdminsRight } from '../../services/api';

import Table from '../../components/Table';
import Layout from '../../components/Layout';
import NoResources from '../../components/NoResources';
import HeaderModal from '../../components/Modals/HeaderModal';

const Memberships = () => {
  const { groups, parentNetworkId } = useContext(GlobalContext);

  const [admins, setAdmins] = useState();
  const [refresh, setRefreshing] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [canAddAdmin, setCanAddAdmin] = useState(false);

  const loadResource = async () => {
    const fetchedAdmins = await getAdmins(parentNetworkId, groups);
    const canEdit = await getAdminsRight(parentNetworkId, groups);
    setCanAddAdmin(canEdit);
    setAdmins(formatAdminData(fetchedAdmins, setRefreshing, parentNetworkId));
  };

  return (
    <Layout
      refresh={refresh}
      loadResource={loadResource}
      setRefreshing={setRefreshing}
      pageHeaderTitle="Mon équipe & droits d’administration"
      pageHeaderButtons={[
        { title: 'Ajouter un membre d\'équipe', onClick: () => setModalType('add-admin'), active: canAddAdmin },
        { title: 'Inviter un admin externe', onClick: () => setModalType('invite-admin'), active: canAddAdmin },
      ]}
    >
      <HeaderModal setRefreshing={setRefreshing} modalIsOpen={modalType !== null} setModalOpen={() => setModalType(null)} type={modalType} />
      {
        admins?.length > 0 ? (
          <Table setRefreshing={setRefreshing} columns={tableColumns} data={admins} type="admin" />
        ) : (
          <NoResources
            noResourceText="Vous n'avez pas encore d'admin"
            findResourceText="Une fois votre premier admin ajouté vous le retrouverez ici!"
            buttons={canAddAdmin && [
              { title: 'Ajouter un membre d\'équipe', onClick: () => setModalOpen(true) },
              { title: 'Inviter un admin externe', onClick: () => setModalOpen(true) }
            ]}
          />
        )
      }
    </Layout>
  );
};

export default Memberships;
