import React, { useContext, useState } from 'react';

import { GlobalContext } from '../../contexts/global';
import { getAdhesion, getMembers, getMembersRight } from '../../services/api';

import tableColumns from './tableColumns';
import adhesionTableColumns from './adhesionTableColumns';
import { formatInternAdhesionData, formatMembersData } from './formatData';

import Table from '../../components/Table';
import Layout from '../../components/Layout';
import NoResources from '../../components/NoResources';
import TableFilters from '../../components/Table/Filters';
import HeaderModal from '../../components/Modals/HeaderModal';

const Memberships = () => {
  const [modalType, setModalType] = useState();
  const [filterValue, setFilterValue] = useState();
  const [members, setMembers] = useState([]);
  const [adhesion, setAdhesion] = useState([]);
  const [refresh, setRefreshing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [canAddMember, setCanAddMember] = useState(false);

  const { groups, parentNetworkId } = useContext(GlobalContext);
  const loadResource = async () => {
    const fetchedMembers = await getMembers(parentNetworkId, groups);
    const fetchAdhesion = await getAdhesion(parentNetworkId, 'INTERN', groups);
    setAdhesion(formatInternAdhesionData(fetchAdhesion, parentNetworkId));
    const canEdit = await getMembersRight(parentNetworkId, groups);
    setCanAddMember(canEdit);
    setMembers(formatMembersData(fetchedMembers, parentNetworkId));
  };

  return (
    <Layout
      refresh={refresh}
      loadResource={loadResource}
      setRefreshing={setRefreshing}
      pageHeaderTitle="Mes membres"
      pageHeaderButtons={[
        {
          title: 'Ajouter un Membre',
          onClick: () => {
            setModalType('add-member');
            setModalOpen(true);
          },
          active: canAddMember,
        },
        /*
        {
          title: 'Inviter mes membres à rejoindre Othr', onClick: () => {
            setModalType('invit-member');
            setModalOpen(true);
          }
        },
         */
      ]}
    >
      <HeaderModal setRefreshing={setRefreshing} modalIsOpen={modalOpen} setModalOpen={setModalOpen} type={modalType} />
      {
        adhesion.length > 0 && (
          <div style={{ marginBottom: 80 }}>
            <div style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
              Font-ils partie de votre réseau ?
            </div>
            <Table setRefreshing={setRefreshing} columns={adhesionTableColumns} data={adhesion} type="adhesion" />
          </div>
        )
      }
      {
        members?.length > 0 && (
          <TableFilters
            value={filterValue}
            setValue={setFilterValue}
            placeholder="Prénom/Nom"
            onFilterChange={(filter) => {
              const getTargetData = (item) => `${item.data.firstname.toLowerCase()} ${item.data.lastname.toLowerCase()}`;
              if (filter === 'asc') {
                setMembers([...members.sort((a, b) => getTargetData(a) > getTargetData(b) ? 1 : -1)]);
              } else if (filter === 'desc') {
                setMembers([...members.sort((a, b) => getTargetData(a) < getTargetData(b) ? 1 : -1)]);
              }
            }}
            filters={[
              {
                placeholder: 'Prénom/Nom',
                name: 'Classer par:',
                options: [
                  {
                    name: 'A à Z',
                    value: 'asc',
                  },
                  {
                    name: 'Z à A',
                    value: 'desc',
                  },
                ],
              }
            ]}
          />
        )
      }
      {
        members?.length > 0 ? (
          <Table
            type="user"
            data={members}
            columns={tableColumns}
            validateType="new-member"
            setRefreshing={setRefreshing}
            validate={(row) => row.values.data.isNew === true}
            rowProps={row => ({
              onClick: () => console.log(row.values),
              // style: { cursor: 'pointer' }
            })}
          />
        ) : (
          <NoResources
            noResourceText="Vous n'avez pas encore de membre"
            findResourceText="Une fois votre premier membre ajouté vous le retrouverez ici!"
            buttons={canAddMember ? [
              {
                title: 'Ajouter un membre',
                onClick: () => {
                  setModalType('add-member');
                  setModalOpen(true);
                }
              },
              {
                title: 'Inviter un membre',
                onClick: () => {
                  setModalType('invit-member');
                  setModalOpen(true);
                }
              },
            ] : [
              {
                title: 'Inviter un membre',
                onClick: () => {
                  setModalType('invit-member');
                  setModalOpen(true);
                }
              },
            ]}
          />
        )
      }
    </Layout>
  );
};

export default Memberships;
