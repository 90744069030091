import React, { useContext, useState } from 'react';

import { REACT_APP_API_URL } from '../../config';
import { GlobalContext } from '../../contexts/global';
import EventsIcon from '../../assets/pageIcon/events.svg';

import tableColumns from './tableColumns';
import { formatEventData } from './formatData';
import { getEvents, getEventsRight } from '../../services/api';

import Table from '../../components/Table';
import Layout from '../../components/Layout';
import NoResources from '../../components/NoResources';
import TableFilters from '../../components/Table/Filters';

const Events = () => {
  const { groups, parentNetworkId } = useContext(GlobalContext);

  const [events, setEvents] = useState();
  const [filterValue, setFilterValue] = useState();
  const [canAddEvent, setCanAddEvents] = useState(false);

  const loadResource = async () => {
    const fetchedEvents = await getEvents(parentNetworkId, groups);
    const canEdit = await getEventsRight(parentNetworkId, groups);
    setCanAddEvents(canEdit);
    setEvents(formatEventData(fetchedEvents, parentNetworkId));
  };

  return (
    <Layout
      loadResource={loadResource}
      pageHeaderIcon={EventsIcon}
      pageHeaderTitle="Mes events"
      pageHeaderButtons={[
        { title: 'Publier un Event', onClick: () => window.open(`${REACT_APP_API_URL}/application/events/create`), active: canAddEvent },
      ]}
    >
      {
        events?.length > 0 && (
          <TableFilters
            value={filterValue}
            setValue={setFilterValue}
            onFilterChange={(filter) => {
              const getTargetData = (item) => item.title;
              if (filter === 'asc') {
                setEvents([...events.sort((a, b) => getTargetData(a) > getTargetData(b) ? 1 : -1)]);
              } else if (filter === 'desc') {
                setEvents([...events.sort((a, b) => getTargetData(a) < getTargetData(b) ? 1 : -1)]);
              } else if (filter.includes('sell')) {
                if (filter.includes('asc')) {
                  setEvents([...events.sort((a, b) => a.tickets.length > b.tickets.length ? 1 : -1)]);
                }
                if (filter.includes('desc')) {
                  setEvents([...events.sort((a, b) => a.tickets.length < b.tickets.length ? 1 : -1)]);
                }
              }
            }}
            filters={[
              {
                placeholder: 'Titre',
                name: 'Classer par:',
                options: [
                  {
                    name: 'A à Z',
                    value: 'asc',
                  },
                  {
                    name: 'Z à A',
                    value: 'desc',
                  },
                ],
              },
              {
                placeholder: 'Tous',
                name: 'Ventes',
                options: [
                  {
                    name: '+ au -',
                    value: 'sell-desc',
                  },
                  {
                    name: '- au +',
                    value: 'sell-asc',
                  },
                ],
              }
            ]}
          />
        )
      }
      {
        events?.length > 0 ? (
          <Table
            type="event"
            data={events}
            columns={tableColumns}
          />
        ) : (
          <NoResources
            noResourceText="Vous n'avez pas encore d'event"
            findResourceText="Créer un event pour le retrouver ici"
            buttons={canAddEvent && [
              {
                title: 'Créer un event',
                onClick: () => window.open(`${REACT_APP_API_URL}/application/events/create`),
              },
            ]}
          />
        )
      }
    </Layout>
  );
};

export default Events;
