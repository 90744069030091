export default [
  {
    type: 'profile',
    Header: 'Prénom/Nom',
    accessor: 'name', // accessor is the "key" in the data
  },
  {
    type: 'information-italic-grey',
    Header: 'Groupe',
    accessor: 'groupName', // accessor is the "key" in the data
  },
  {
    type: 'information-italic-grey',
    Header: 'Date',
    fontWeight: 'italic',
    accessor: 'creationDate',
  },
  {
    type: 'contact-icon',
    Header: 'Contact',
    accessor: 'contact', // accessor is the "key" in the data
  },
  {
    type: 'information-status',
    Header: 'Statut',
    accessor: 'status', // accessor is the "key" in the data
  },
  {
    type: ['accept-icon', 'delete-icon'],
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    type: 'hidden',
    accessor: 'data',
  }
];
