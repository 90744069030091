import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import './styles/index.css';
import './styles/global.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
