import React from 'react';

import './styles.css';

const NoResources = ({ noResourceText, findResourceText, buttons }) => (
  <div className="no-resource-container">
    <div className="no-resource-text bold">{noResourceText}</div>
    <div className="find-resource-text italic grey">{findResourceText}</div>
    <div className="action-buttons-container">
      {buttons && buttons.map((button,  index) => (
        <div className="action-button-container" key={index} style={{ marginLeft: index > 0 && 30 }}>
          <button className="action-button" onClick={button.onClick}>
            {button.title}
          </button>
        </div>
      ))}
    </div>
  </div>
);

export default NoResources;
