import { REACT_APP_APP_URL } from '../../config';

const getMediumLink = (media) => {
  const title = media.items?.find((item) => item.type === 'title')?.content;
  if (media.type === 'article') {
    return `${REACT_APP_APP_URL}/articles/${title}-${media.id}`;
  }
  if (media.type === 'video') {
    return `${REACT_APP_APP_URL}/videos/${title}-${media.id}`;
  }
  if (media.type === 'podcast') {
    return `${REACT_APP_APP_URL}/podcasts/${title}-${media.id}`;
  }
};

const seeMediumText = (type) => {
  if (type === 'article') {
    return 'Voir l\'article';
  }
  if (type === 'video') {
    return 'Voir la vidéo';
  }
  if (type === 'podcast') {
    return 'Ecouter le podcast';
  }
};

export const formatMediasData = (medias) => medias.map((media) => ({
  type: media.type,
  sphere: media.sphere.name,
  likes: media.likes?.length,
  views: media.views?.length,
  createdAt: media.createdAt,
  link: {
    onClick: () => window.open(getMediumLink(media)),
    text: seeMediumText(media.type),
  },
  data: {
    title: media.items?.find((item) => item.type === 'title')?.content,
    pictureUrl: media.picture_url,
    ...media,
  },
  actions: [{
    active: true,
    onClick: () => window.open(`${getMediumLink(media)}/edit`),
  }, {
    value: getMediumLink(media)
  }],
}));
