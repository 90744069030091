import './styles.css';

import moment from 'moment';
import { useTable } from 'react-table';
import { useState, Fragment } from 'react';

import TableModal from '../Modals/TableModal';
import TableColumnTooltip from '../TableColumnTooltip';

import TooltipSvg from '../../assets/tooltip.svg';

import OrangeLinkSvg from '../../assets/orangeLink.svg';

import VideoSvg from '../../assets/video.svg';
import ArticleSvg from '../../assets/article.svg';
import PodcastSvg from '../../assets/podcast.svg';

import LinkSvg from '../../assets/link.svg';
import FormSvg from '../../assets/form.svg';
import Upload from '../../assets/upload.svg';
import Download from '../../assets/download.svg';
import DeleteSvg from '../../assets/trash.svg';
import AcceptSvg from '../../assets/accept.png';
import ConsultSvg from '../../assets/consult.png';
import AddUserSvg from '../../assets/addUser.svg';
import ContactSvg from '../../assets/contact.svg';
import EditMedium from '../../assets/editMedium.svg';
import EditEvent from '../../assets/editEent.svg';
import EditUserSvg from '../../assets/editUser.svg';
import GreenLinkSvg from '../../assets/greenLink.svg';
import EditUserGreySvg from '../../assets/editUserGrey.svg';

import GreyPageSvg from '../../assets/menuIconGrey/page.svg';
import GreyEventSvg from '../../assets/menuIconGrey/event.svg';
import GreyMediaSvg from '../../assets/menuIconGrey/media.svg';
import GreyAdminSvg from '../../assets/menuIconGrey/admin.svg';
import GreyMemberSvg from '../../assets/menuIconGrey/members.svg';
import GreyHandleMembersSvg from '../../assets/menuIconGrey/handle-members.svg';

import GreenPageSvg from '../../assets/menuIconGreen/page.svg';
import GreenEventSvg from '../../assets/menuIconGreen/event.svg';
import GreenMediaSvg from '../../assets/menuIconGreen/media.svg';
import GreenAdminSvg from '../../assets/menuIconGreen/admin.svg';
import GreenMemberSvg from '../../assets/menuIconGreen/members.svg';
import GreenHandleMembersSvg from '../../assets/menuIconGreen/handle-members.svg';

import CopyToClipboard from '../CopyToClipboard';

const CellContainer = ({ cell, bg, className, children }) => (
  <td
    {...cell.getCellProps()}
    className={`cell ${className}`}
    style={{ backgroundColor: bg }}
  >
    {children}
  </td>
);

const ContributionCell = ({ cell, bg, setModal }) => {
  let dotColor = '#BC1E2C';
  if (cell.value) {
    const currentDate = new Date();
    const oneMonthAgo = new Date(cell.value);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const twoMonthAgo = new Date(cell.value);
    twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);
    if (currentDate > oneMonthAgo) {
      dotColor = '#BC1E2C';
    } else if (currentDate > twoMonthAgo) {
      dotColor = '#FAA60E';
    } else {
      dotColor = '#28CB80';
    }
  }
  const d = new Date(cell.value);
  d.setMonth(d.getMonth() - 2);
  const c = new Date();
  const disable = (!cell.value && cell.row.values.data.pendingContribution) || c < d;
  return (
    <CellContainer
      bg={bg}
      cell={cell}
      className={`${cell.column.type.includes('italic') && 'italic'} ${cell.column.type.includes('grey') && 'grey'}`}
    >
      <div
        onClick={disable ? () => {} : () => setModal('contribution', cell.row.values)}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: -10, cursor: disable ? 'not-allowed' : 'pointer' }}
      >
        {(cell.value && moment(cell.value).format('DD/MM/YYYY')) || (cell.row.values.data.pendingContribution ? 'En attente de paiement' : 'Aucun paiement')}
        <div style={{ marginLeft: 5, width: 15, height: 15, borderRadius: 7.5, marginTop: 2, backgroundColor: dotColor }}/>
      </div>
    </CellContainer>
  );
};

const InformationCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
    className={`${cell.column.type.includes('italic') && 'italic'} ${cell.column.type.includes('grey') && 'grey'}`}
  >
    {cell.value}
  </CellContainer>
);

const LinkCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div
      onClick={cell.value.onClick}
      style={{ cursor: 'pointer' }}
      className={`${cell.column.type.includes('italic') && 'italic'} ${cell.column.type.includes('grey') && 'grey'} underline`}
    >
      {cell.value.text}
    </div>
  </CellContainer>
);

const ProfileCell = ({ cell, bg, validateType }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="cell-row-content">
      <img src={cell.row.values.data.pictureUrl || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} className="cell-profile-pic" alt="cell-profile-pic"/>
      <div>
        {
          validateType === 'new-member' && (
            <div className="cell-profile-new-user">
              Nouveau membre
            </div>
          )
        }
        <div className="cell-profile-name">
          {cell.value}
        </div>
      </div>
    </div>
  </CellContainer>
);

const MediumTitleCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="cell-row-content">
      <img src={cell.row.values.data.pictureUrl} className="cell-event-title-pic" alt="cell-event-title-pic"/>
      <div className="cell-event-title">
        {cell.row.values.data.title}
      </div>
    </div>
  </CellContainer>
);

const MediumDateCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="bold cell-date">
      {moment(cell.value).format('DD/MM/YYYY')}
    </div>
    <div className="italic grey">
      Modifé le {moment(cell.row.values.data.updatedAt).format('DD/MM/YYYY')}
    </div>
  </CellContainer>
);

const MediumTypeCell = ({ cell, bg }) => {
  let svg;
  if (cell.value === 'article') {
    svg = ArticleSvg;
  }
  if (cell.value === 'video') {
    svg = VideoSvg;
  }
  if (cell.value === 'podcast') {
    svg = PodcastSvg;
  }

  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img src={svg} style={{ width: 18, height: 18, marginRight: 10 }} alt="media-type" />
        {cell.value.charAt(0).toUpperCase() + cell.value.slice(1)}
      </div>
    </CellContainer>
  );
};

const EventTitleCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="cell-row-content">
      <img src={cell.row.values.data.pictureUrl} className="cell-event-title-pic" alt="cell-event-title-pic"/>
      <div className="cell-event-title">
        {cell.value}
      </div>
    </div>
  </CellContainer>
);

const EventDateCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="bold cell-date">
      Le {moment(cell.value.startDate).format('DD/MM/YYYY')}
    </div>
    <div className="italic cell-date grey">
      de {moment(cell.value.startDate).format('HH:mm')} à {moment(cell.value.endDate).format('HH:mm')}
    </div>
  </CellContainer>
);

const EventTicketsCell = ({ cell, bg }) => {
  let takenColor = '';
  if (cell.value?.takenPlaces >= cell.value?.totalPlaces * 0.6) {
    takenColor = 'green';
  } else if (cell.value?.takenPlaces >= cell.value?.totalPlaces * 0.3) {
    takenColor = 'orange';
  } else {
    takenColor = 'red';
  }
  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      <div className="cell-row-content">
        <div className={`${takenColor} cell-event-tickets`}>
          {cell.value?.takenPlaces >= 10 ? cell.value?.takenPlaces : `0${cell.value?.takenPlaces}`}
        </div>
        <div className="grey cell-event-total-places">
          / {cell.value?.totalPlaces}
        </div>
      </div>
      <div className="grey italic cell-event-remaining-tickets">
        {cell.value?.totalPlaces - cell.value?.takenPlaces} Places restantes
      </div>
    </CellContainer>
  );
};

const EventStatus = ({ cell, bg }) => {
  let statusText = '';
  let statusClass = '';
  if (cell.value === 'open') {
    statusText = 'Inscriptions ouvertes';
    statusClass = 'cell-event-status-open';
  }
  if (cell.value === 'full') {
    statusText = 'Complet';
    statusClass = 'cell-event-status-full';
  }

  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      <div className={statusClass}>
        <div className={`cell-event-status-text ${cell.value === 'full' && 'white'}`}>
          {statusText}
        </div>
      </div>
    </CellContainer>
  );
};

const AddressCell = ({ cell, bg }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div className="italic grey cell-event-address">
      {cell.value?.number} {cell.value?.street}
    </div>
    <div className="italic grey cell-event-address">
      {cell.value?.zip_code} {cell.value?.city}
    </div>
  </CellContainer>
);

const PaymentLinkCell = ({ cell, bg, setModal }) => {
  let statusText = '';
  let color = '';
  let svg = '';
  if (cell.value === 'pending') {
    statusText = 'Envoyer';
    color = 'black';
    svg = LinkSvg;
  }
  if (cell.value === 'waiting-payment') {
    statusText = 'Envoyé';
    color = 'orange';
    svg = OrangeLinkSvg;
  }
  if (cell.value === 'paid') {
    color = 'green';
    statusText = 'Payé';
    svg = GreenLinkSvg;
  }
  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      {
        cell.value && (
          <div
            className={`cell-row-content ${color} cell-link ${cell.value === 'paid' && 'cell-link-green-border'} ${cell.value === 'waiting-payment' && 'cell-link-orange-border'}`}
            style={cell.value === 'pending' ? { cursor: 'pointer' } : {}}
            onClick={() => cell.value === 'pending' && setModal('first-contribution', cell.row.values)}
          >
            <img src={svg} className="cell-link-icon" />
            <div className={color}>
              {statusText}
            </div>
          </div>
        )
      }
    </CellContainer>
  );
};

const IconCell = ({ cell, bg, setModal }) => (
  <CellContainer
    bg={bg}
    cell={cell}
  >
    <div style={{ width: '50%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
      {cell.column.type.includes('request') &&
        <img src={FormSvg} className="cell-icon" onClick={() => setModal('request', cell.row.values)} />}
      {cell.column.type.includes('contact') &&
        <img src={ContactSvg} className="cell-icon" onClick={() => setModal('form-details', cell.row.values)}/>}
    </div>
  </CellContainer>
);

const ArrayIconCell = ({ cell, bg, setModal }) => {
  const canEdit = (cell.row.values.actions?.canEdit === false || cell.row.values.canEdit === false);

  const style = {
    pointerEvents: canEdit && 'none',
  };

  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      <div style={{ cursor: canEdit && 'not-allowed', alignItems: 'center', flexDirection: 'row', width: '80%', display: 'flex', justifyContent: 'space-between' }}>
        {
          cell.column.type.map((t, index) => {
            if (t.includes('add')) { return <img key={index} src={AddUserSvg} className="cell-icon" style={style} onClick={() => setModal('form-details', cell.row.values)} />; }
            if (t.includes('request')) { return <img key={index} src={FormSvg} className="cell-icon" style={style} onClick={() => setModal('request', cell.row.values)} />; }
            if (t.includes('contact')) { return <img key={index} src={ContactSvg} className="cell-icon" style={style} onClick={() => setModal('form-details', cell.row.values)} />; }
            if (t.includes('consult-event')) { return <img key={index} src={EditEvent} className="cell-icon" style={style} onClick={cell.row.values.actions[index].onClick} />; }
            if (t.includes('consult-medium')) { return <img key={index} src={EditMedium} className="cell-icon" style={style} onClick={cell.row.values.actions[index].onClick} />; }
            if (t.includes('consult')) { return <img key={index} src={ConsultSvg} className="cell-icon" style={style} onClick={cell.row.values.actions[index].onClick} />; }
            if (t.includes('delete')) { return <img key={index} src={DeleteSvg} className="cell-medium-icon" style={style} onClick={() => setModal('delete', cell.row.values)} />; }
            if (t.includes('accept')) { return <img key={index} src={AcceptSvg} className="cell-small-icon" style={style} onClick={() => setModal('accept', cell.row.values)} />; }
            if (t.includes('download')) { return <img key={index} src={Download} className="cell-icon" style={style} onClick={cell.row.values.actions[index].onClick} />; }
            if (t.includes('upload')) { return (
              <div key={index}>
                <CopyToClipboard copyText="Lien copié, vous pouvez maintenant le partager !" text={cell.row.values.actions[index].value}>
                  <img src={LinkSvg} className="cell-icon" style={style} />
                </CopyToClipboard>
              </div>
            );}
            if (t.includes('edit')) { return (
              <img
                key={index}
                onClick={cell.value[index]?.active ? () => setModal('form-edit', cell.row.values) : () => {}}
                src={cell.value[index]?.active ? EditUserSvg : EditUserGreySvg}
                className={cell.value[index]?.active ? 'cell-icon' : 'cell-icon-disabled'}
                style={style}
              />
            );}
          })
        }
      </div>
    </CellContainer>
  );
};
const RightCell = ({ cell, bg }) => {
  const canEdit = (cell.row.values.actions?.canEdit === false || cell.row.values.canEdit === false);

  const style = {
    pointerEvents: canEdit && 'none',
  };

  return (
    <CellContainer
      bg={bg}
      cell={cell}
    >
      <div style={{ cursor: canEdit && 'not-allowed', width: '50%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
        {cell.column.type.includes('adhesion') &&
          <img src={cell.value?.active ? GreenHandleMembersSvg : GreyHandleMembersSvg} className="cell-icon" style={style} onClick={cell.value?.onClick}/>}
        {cell.column.type.includes('members') &&
          <img src={cell.value?.active ? GreenMemberSvg : GreyMemberSvg} className="cell-icon" style={style} onClick={cell.value?.onClick} />}
        {cell.column.type.includes('page') &&
          <img src={cell.value?.active ? GreenPageSvg : GreyPageSvg} className="cell-icon" style={style} onClick={cell.value?.onClick} />}
        {cell.column.type.includes('events') &&
          <img src={cell.value?.active ? GreenEventSvg : GreyEventSvg} className="cell-icon" style={style} onClick={cell.value?.onClick} />}
        {cell.column.type.includes('medias') &&
          <img src={cell.value?.active ? GreenMediaSvg : GreyMediaSvg} className="cell-icon" style={style} onClick={cell.value?.onClick} />}
        {cell.column.type.includes('admins') &&
          <img src={cell.value?.active ? GreenAdminSvg : GreyAdminSvg} className="cell-icon" style={style} onClick={cell.value?.onClick} />}
      </div>
    </CellContainer>
  );
};

const CellContent = ({
  bg,
  cell,
  setModal,
  validateType,
}) => {
  if (Array.isArray(cell.column.type) && !cell.row.values.actions?.disabled) {
    return <ArrayIconCell
      bg={bg}
      cell={cell}
      setModal={setModal}
    />;
  }
  if (cell.column.type === 'profile') {
    return <ProfileCell cell={cell} bg={bg} validateType={validateType} />;
  }
  if (cell.column.type.includes('icon')) {
    return <IconCell cell={cell} bg={bg} setModal={setModal} />;
  }
  if (cell.column.type === 'payment_link') {
    return <PaymentLinkCell cell={cell} bg={bg} setModal={setModal} />;
  }
  if (cell.column.type === 'contribution') {
    return <ContributionCell cell={cell} bg={bg} setModal={setModal} />;
  }
  if (cell.column.type.includes('information')) {
    return <InformationCell cell={cell} bg={bg} />;
  }
  if (cell.column.type.includes('link')) {
    return <LinkCell cell={cell} bg={bg} />;
  }
  if (cell.column.type.includes('right')) {
    return <RightCell cell={cell} bg={bg} /> ;
  }
  if (cell.column.type === 'event_title') {
    return <EventTitleCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'event_date') {
    return <EventDateCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'event_tickets') {
    return <EventTicketsCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'event_status') {
    return <EventStatus cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'medium_title') {
    return <MediumTitleCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'medium_date') {
    return <MediumDateCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'medium_type') {
    return <MediumTypeCell cell={cell} bg={bg} />;
  }
  if (cell.column.type === 'address') {
    return <AddressCell cell={cell} bg={bg} />;
  }
  if (cell.column.type.includes('hidden')) {
    return null;
  }
  return (
    <CellContainer cell={cell} bg={bg} />
  );
};

const rightsTooltipMessages = {
  adhesion: 'Gestion des adhésions',
  members: 'Gestion des membres',
  page: 'Gestion des pages',
  events: 'Gestion des events',
  medias: 'Gestion des média',
  admins: 'Gestion de l\'équipe',
};

const Table = ({ data, setRefreshing, columns, type, validateType, validate = () => {}, rowProps = () => {} }) => {
  const [modalData, setModalData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(undefined);

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
  } = useTable({ columns, data });

  const setModal = (modalType, data) => {
    setModalData(data);
    setModalType(modalType);
    setModalOpen(true);
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <TableColumnTooltip />
      <TableModal setRefreshing={setRefreshing} data={modalData} modalIsOpen={modalOpen} setModalOpen={setModalOpen} dataType={type} type={modalType} />
      <table
        {...getTableProps()}
        style={{ width: '100%', borderCollapse: 'collapse' }}
      >
        <thead style={{ overflowY: 'scroll' }}>
          {headerGroups.map((headerGroup, headerIndex) => (
            <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerColumnIndex) => column.type !== 'hidden' && (
                <th
                  key={headerColumnIndex}
                  {...column.getHeaderProps()}
                  className="black column-header-container"
                >
                  <div className="column-header-content">
                    <div className="column-header-title">
                      {column.type !== 'link' && column.Header}
                    </div>
                    {
                      !['icon', 'link', 'right', 'tickets', 'date', 'address', 'nofilter'].some((t) => {
                        if (Array.isArray(column.type)) {
                          return column.type.some((c) => c.includes(t));
                        }
                        return column.type.includes(t);
                      })
                    }
                    {
                      column.type.includes('right') && (
                        <img src={TooltipSvg} data-tip={rightsTooltipMessages[column.type.split('-')[1]]} data-for="table" style={{ marginLeft: 5, marginTop: 2, width: 15, height: 15 }} alt="tooltip" />
                      )
                    }
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            let color = 'white';
            if (validate(row)) {
              color = 'rgba(40, 203, 128, 0.2)';
            } else if (index % 2) {
              color = '#F4F4F4';
            }
            return (
              <tr key={index} {...row.getRowProps(rowProps(row))}>
                {row.cells.map((cell, index) => (
                  <Fragment key={index}>
                    <CellContent
                      bg={color}
                      cell={cell}
                      setModal={setModal}
                      validateType={validate(row) && validateType}
                    />
                  </Fragment>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
