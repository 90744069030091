export default [
  {
    type: 'profile',
    Header: 'Prénom/Nom',
    accessor: 'name',
  },
  {
    type: 'information-italic-grey',
    Header: 'Groupe',
    accessor: 'group',
  },
  {
    type: 'right-adhesion',
    Header: 'Adhésions',
    accessor: 'rightAdhesion',
  },
  {
    type: 'right-members',
    Header: 'Membres',
    accessor: 'rightMembers',
  },
  {
    type: 'right-page',
    Header: 'Page',
    accessor: 'rightPage',
  },
  {
    type: 'right-events',
    Header: 'Events',
    accessor: 'rightEvents',
  },
  {
    type: 'right-medias',
    Header: 'Media',
    accessor: 'rightMedias',
  },
  {
    type: 'right-admins',
    Header: 'Equipe',
    accessor: 'rightAdmins',
  },
  {
    type: ['contact-icon', 'delete-icon'],
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    type: 'hidden',
    accessor: 'data',
  }
];
