export default [
  {
    type: 'medium_title',
    Header: 'Media',
    accessor: 'title',
  },
  {
    type: 'medium_type',
    Header: 'Type',
    accessor: 'type',
  },
  {
    type: 'medium_date',
    Header: 'Date',
    accessor: 'createdAt',
  },
  {
    type: 'information-italic-nofilter',
    Header: 'Sphère',
    accessor: 'sphere',
  },
  {
    type: 'information',
    Header: 'Audience',
    accessor: 'views',
  },
  {
    type: 'information',
    Header: 'Pertinence',
    accessor: 'likes',
  },
  {
    type: ['consult-medium-icon', 'upload-icon'],
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    type: 'link',
    Header: 'Consulter',
    accessor: 'link',
  },
  {
    type: 'hidden',
    accessor: 'data',
  },
];
